<template lang="pug">
  .kr-card-form(v-if="isRendered" :class="dynamicClasses")
    slot
</template>

<script>
import { mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

import { CardFormThemeMixin } from '@/host/components/mixins/CardFormTheme'
import BinUpdateRequest from '@/host/components/mixins/BinUpdateRequest'

/**
 * .kr-embedded form is now in rendered as a component to:
 * - Allow passing the form ID to any component in the subtree
 * - Better component reading inside Vue debug tool.
 *
 * @since KJS-3010
 */
export default {
  name: 'KryptonCardForm',
  mixins: [CardFormThemeMixin, BinUpdateRequest],
  provide() {
    return {
      context: this.context
    }
  },
  data() {
    return {
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapGetters(['isCardFormRendered']),
    ...dynamicMapState('context.namespace', ['disabledCardForm']),
    dynamicClasses() {
      const domClasses = Array.from(this.$el?.classList || []).reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {}
      )
      return {
        ...domClasses,
        'kr-embedded-enabled': !this.disabledCardForm,
        'kr-embedded-disabled': this.disabledCardForm
      }
    },
    // Set by DNA reader for main form, by FormRenderer for clone in order to
    // Allow reactivity for forms added later on
    isRendered() {
      return this.isCardFormRendered(this.context.formId)
    }
  },
  created() {
    this.context.formId = this.$attrs['kr-form']
    this.context.namespace = `cardForm_${this.context.formId}`
  }
}
</script>
