import { extend, isUndefined, defaults, has, each, isEmpty } from 'underscore'
import deepExtend from 'deep-extend'
import { getDomFields } from '@/common/util/dom'
import Events from '@/configuration/Events'
import whitelistedFields from '@/configuration/sources/WhitelistedFields.yml'
import fieldBaseConfig from '@/configuration/sources/FieldBaseConfiguration.yml'
import identityTypes from '@/configuration/sources/IdentityTypes.yml'
import currencyCountry from '@/configuration/sources/CurrencyCountry.yml'
import { isWallet, getTokenHelper } from '@/host/service/DNA/Helper/wallet.js'
import { isSmartFormForcedTokenHelper } from '@/host/service/DNA/Helper/isSmartFormForcedTokenHelper.js'
import { MethodDescriptor } from '@/common/model/PaymentMethod'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export default class Reader {
  defaultBaseConfig

  constructor({ $store, $bus }) {
    this.$store = $store
    this.$bus = $bus
    this.isDebitBrand = $store.getters.isDebitBrand
  }

  parse(dnaParam) {
    const dna = this.removeSmartFormMethodsIfTokensForced(dnaParam)

    this.checkDomCoherence(dna)
    this.setPaymentMethods(dna.cards, dna.smartForm || {}, dna.tokens)
    this.setDefaultIdentityDocType(dna)

    /** HACKS **/
    defaults(dna, { cards: { DEFAULT: { fields: {} } } })
    dna.cards = this.addDomFields(dna.cards)
    dna.cards = this.addMissingFieldsAsHidden(dna.cards)
    this.checkFieldVisibility(dna.cards)
    this.removeUnsupportedTokens(dna.tokens)

    dna.cards = this.generateBrands(dna.cards)
    dna.cards = this.generateTokens(dna.cards, dna.tokens)
    this.isDynamic(dna.cards)

    return dna
  }

  /**
   * If kr-embedded is found alone and DNA does not have cards, raise
   * an error. If any smartform is found then it can handle the cards form.
   *
   * @since KJS-2514
   */
  checkDomCoherence(dna) {
    if (isUndefined(dna.cards) && !this.$store.getters.hasSmartElements()) {
      this.$store.dispatch('error', 'CLIENT_508')
      this.$bus.$emit(Events.krypton.data.errorAlert)
    }
  }

  setDefaultIdentityDocType(dna) {
    this.defaultBaseConfig = fieldBaseConfig

    const country = dna.country ?? currencyCountry[dna.currency]
    if (identityTypes[country]) {
      this.defaultBaseConfig.identityDocumentType.values =
        identityTypes[country]
    }
  }
  generateBrands(cards) {
    for (const brand in cards) {
      const brandConf = cards[brand]
      let extFieldConf = brandConf

      // If it has copyFrom attribute, extend the config
      if (brandConf.copyFrom) {
        const parentBrand = brandConf.copyFrom.replace('cards.', '')
        let baseConf = JSON.parse(JSON.stringify(cards[parentBrand]))
        if (!brandConf.fields) brandConf.fields = {}
        extFieldConf = deepExtend(baseConf, brandConf)
        delete extFieldConf.copyFrom

        // Don't extend values
        for (const field in extFieldConf.fields) {
          if (!brandConf.fields) continue
          const fieldConf = extFieldConf.fields[field]
          if (fieldConf.values && brandConf.fields[field]?.values) {
            extFieldConf.fields[field].values = brandConf.fields[field].values
          }
        }
      }

      this.purgeNonWhitelistedFields(extFieldConf)
      cards[brand] = extFieldConf
    }

    return cards
  }

  generateTokens(cards, tokens) {
    let formMode = 'default'
    let paymentMethodToken = null

    // Any card or payment method has a token
    let cardsHaveToken = tokens?.cards?.some(card => card.token)
    const smartFormHasTokens = tokens?.smartForm?.some(pm => pm.token)
    if (cardsHaveToken || smartFormHasTokens) {
      // Cards - filter the invalid tokens (brand must be in cards)
      tokens.cards = tokens.cards?.filter(card => has(cards, card.brand)) ?? []
      // Cards - extend from the main config
      for (const pos in tokens.cards) {
        const card = tokens.cards[pos]

        // Set the token config in the main dna
        if (tokens.cards.length === 1 && tokens.forced) {
          // Extend the DNA brand conf
          const brandConf = cards[card.brand]
          for (const field in card.fields) {
            if (!brandConf.fields[field]) brandConf.fields[field] = {}
            brandConf.fields[field] = extend(
              JSON.parse(JSON.stringify(brandConf.fields[field])),
              card.fields[field]
            )
          }
        }
      }

      // Recheck if the cards have token
      cardsHaveToken = tokens?.cards?.some(card => card.token)
      if (cardsHaveToken || smartFormHasTokens) {
        // If the cards have no token, they are no valid
        formMode = tokens.forced ? 'token' : 'wallet'

        // If the token is forced, we set it
        if (tokens.forced && cardsHaveToken) {
          paymentMethodToken = tokens.cards[0].token
        }
      }
    }

    this.$store.dispatch('update', { formMode })

    if (!isEmpty(this.$store.state.forms)) {
      each(this.$store.state.forms, formId => {
        const previousPmt =
          this.$store?.state[`cardForm_${formId}`]?.nonSensitiveValues
            ?.paymentMethodToken
        const previousPmtIsValid = getTokenHelper(tokens?.cards, previousPmt)
        if (!tokens?.forced && previousPmtIsValid) {
          paymentMethodToken = previousPmt
        }
        if (paymentMethodToken !== previousPmt)
          this.$store.dispatch(`cardForm_${formId}/updateNonSensitiveValue`, {
            paymentMethodToken
          })
      })
    }
    this.$store.dispatch('addFormPreset', {
      action: 'updateNonSensitiveValue',
      params: { paymentMethodToken }
    })

    return cards
  }

  /** SmartForm listed payment methods */
  setPaymentMethods(cards, smartForm, tokens = undefined) {
    let paymentMethods = []

    // Add smartform payment methods
    if (smartForm && Object.keys(smartForm).length) {
      paymentMethods = Object.keys(smartForm).sort((a, b) => {
        const rankA = smartForm[a].rank + 1 || Infinity
        const rankB = smartForm[b].rank + 1 || Infinity
        return rankA - rankB
      })

      this._sortAlmaEntries(paymentMethods)
    } else if (cards && Object.keys(cards).length) paymentMethods = ['CARDS']

    // Fallback, cards not defined in smartForm but it has config
    if (
      !~paymentMethods.indexOf('CARDS') &&
      cards &&
      Object.keys(cards).length &&
      !isSmartFormForcedTokenHelper(tokens)
    )
      paymentMethods.unshift('CARDS')

    // Whitelist - allowed methods
    const { isMethodWhitelisted } = this.$store.getters
    paymentMethods = paymentMethods.filter(method =>
      isMethodWhitelisted(method)
    )

    // For WebView, disable popup/tab methods
    if (this.$store.state.isWebView)
      paymentMethods = paymentMethods.filter(
        method =>
          smartForm[method]?.allowIFrame ||
          method === 'CARDS' ||
          method === 'APPLE_PAY'
      )

    this.$store.dispatch('setPaymentMethods', paymentMethods)
    if (cards) this.$store.dispatch('setCardBrands', Object.keys(cards))

    const { isSinglePaymentButton } = this.$store.getters
    const { selectedMethod } = this.$store.state.smartForm
    if (
      isSinglePaymentButton &&
      isWallet(tokens) &&
      !isMethodAvailable(paymentMethods, tokens, selectedMethod)
    ) {
      const newSelectedMethod = `CARDS:${tokens.cards[0].token}`
      this.$store.dispatch('selectMethod', newSelectedMethod)
    }

    if (!paymentMethods?.length)
      this.$store.dispatch('update', { smartForm: { hidden: true } })
  }

  isDynamic(cards) {
    for (const brand in cards) {
      const brandConf = cards[brand]
      if (
        !!brandConf?.fields?.installmentNumber?.values?.DYNAMIC ||
        !!brandConf?.fields?.firstInstallmentDelay?.values?.DYNAMIC
      ) {
        this.$store.dispatch('update', { hasDynamicValues: true })
        return
      }
    }

    this.$store.dispatch('update', { hasDynamicValues: false })
  }

  purgeNonWhitelistedFields(brandConf) {
    for (const field in brandConf.fields) {
      if (!~whitelistedFields.fields.indexOf(field)) {
        delete brandConf.fields[field]
      }
    }

    return brandConf
  }

  /**
   * HACK : Add missing fields in case the token is updated later
   */
  addMissingFieldsAsHidden(cards) {
    if (isUndefined(cards))
      return { DEFAULT: { fields: this.defaultBaseConfig } }
    for (const fieldName in this.defaultBaseConfig) {
      if (!cards.DEFAULT.fields[fieldName]) {
        cards.DEFAULT.fields[fieldName] = this.defaultBaseConfig[fieldName]
      }
    }
    return cards
  }
  /**
   * HACK: Checks if field should be hidden
   */
  checkFieldVisibility(cards) {
    // Credomatic (costa rica) exception - 1 installment visible
    // Look for an installment value with >1 on the third position
    let oneInstallmentVisible = false
    for (const brand in cards) {
      if (oneInstallmentVisible) break
      const fieldsConf = cards[brand].fields
      if (fieldsConf?.installmentNumber) {
        let values = fieldsConf.installmentNumber.values || {}
        for (const key in values) {
          const value = values[key]
          const optVals = value.split(':')
          if (parseInt(optVals[2]) > 1) {
            oneInstallmentVisible = true
            break
          }
        }
      }
    }

    // HACK: installmentNumber visibility
    for (const brand in cards) {
      const fieldsConf = cards[brand].fields
      if (fieldsConf?.installmentNumber) {
        // If installmentNumber has only 1 value, remove it and hide the field
        let values = fieldsConf.installmentNumber.values || {}
        if (Object.keys(values).length <= 1 && !oneInstallmentVisible) {
          fieldsConf.installmentNumber.required = false
          fieldsConf.installmentNumber.hidden = true
          if (brand !== 'DEFAULT') {
            this.setBrandFallback(fieldsConf.installmentNumber.values, brand)
            delete fieldsConf.installmentNumber
          }
        }

        if (!fieldsConf?.installmentNumber) continue
      }
    }

    // HACK: If any of the fields is visible (and has values) show the field by default
    for (const brand in cards) {
      const fieldsConf = cards[brand].fields
      for (const fieldName in fieldsConf) {
        const fieldConf = fieldsConf[fieldName]
        // Current field is visible and has content
        const isFieldVisible =
          fieldConf.hidden === false &&
          (!fieldConf.values || Object.keys(fieldConf.values).length > 1)
        // It's hidden
        const defaultIsHidden = cards.DEFAULT.fields[fieldName].hidden === true
        // If the field is visible but default one is hidden, show it
        if (isFieldVisible && defaultIsHidden) {
          cards.DEFAULT.fields[fieldName].hidden = false
        }
      }
    }
  }

  // HACK KJS-1480 : hide sepa payment means + KJS-3836 filter not supported methods.
  removeUnsupportedTokens(tokens) {
    const { paymentMethodWhitelist } = paymentMethodsConf.walletTokens

    if (tokens?.smartForm) {
      tokens.smartForm = tokens.smartForm.filter(token =>
        paymentMethodWhitelist.includes(token.paymentMethodType)
      )
    }
  }

  // HACK KJS-1566: 1 option + brand fallback - use global brand fallback
  // instead of by installment value
  setBrandFallback(values, brand) {
    if (!values) return
    const installData = values[Object.keys(values)[0]]
    const opts = installData.split(':')
    if (opts[4]) {
      this.$store.dispatch('addFormPreset', {
        action: 'setBrandFallback',
        params: { [brand]: opts[4] }
      })
    }
  }

  // Add the fields present in the DOM to the DNA with default config
  addDomFields(cards) {
    for (const fieldName of getDomFields()) {
      if (!cards.DEFAULT.fields[fieldName]) {
        cards.DEFAULT.fields[fieldName] = this.defaultBaseConfig[fieldName]
        cards.DEFAULT.fields[fieldName].hidden = false
      }
    }

    return cards
  }

  _sortAlmaEntries(paymentMethods) {
    // Alma multiple paymentMethods -> sort them alphanumerically
    paymentMethods.sort((a, b) => {
      if (!(a.startsWith('ALMA_') && b.startsWith('ALMA_'))) return 0
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    })
  }

  /**
   * HACK. Returns the updated dna. Removing smartForm methods if tokens attribute has forced: true
   * See KJS-3271
   */
  removeSmartFormMethodsIfTokensForced(dnaParam) {
    const dna = JSON.parse(JSON.stringify(dnaParam))
    if (dna?.tokens?.forced) {
      const smartFormTokenType = dna?.tokens?.smartForm?.[0]?.paymentMethodType
      const paymentMethodToPreserve = smartFormTokenType?.startsWith('PAYPAL')
        ? smartFormTokenType
        : 'CARDS'
      dna.smartForm =
        dna.smartForm &&
        Object.fromEntries(
          Object.entries(dna.smartForm).filter(
            ([key, value]) => key === paymentMethodToPreserve
          )
        )
    }
    return dna
  }
}

export function isMethodAvailable(methods, tokens, method) {
  if (!method) return false
  const methodDescriptor = MethodDescriptor.create(method)
  const activeMethod = methodDescriptor.name
  const metadata = methodDescriptor.metadata

  const cardTokens = tokens?.cards || []
  const smartFormTokens = tokens?.smartForm || []
  const allTokens = [...cardTokens, ...smartFormTokens].map(
    ({ token }) => token
  )
  return (
    (allTokens.includes(metadata) && methods.includes(activeMethod)) ||
    methods.includes(method)
  )
}
