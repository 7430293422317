import _ from 'underscore'
import Fields from '@/configuration/sources/Fields.yml'

export const orderFields = fields => {
  // Unlink from the dna config
  fields = JSON.parse(JSON.stringify(fields))

  // Add the field name in the field conf
  for (const fieldName in fields) {
    fields[fieldName].fieldName = fieldName
  }

  fields = _.sortBy(fields, iterItem => {
    return getFieldPosition(iterItem.fieldName)
  })

  return fields
}

export const getFieldPosition = fieldName => {
  if (!Fields.order.includes(fieldName)) return Fields.order.length + 1
  return Fields.order.indexOf(fieldName) + 1
}
