<template lang="pug">
    .krypton-tab-handler(kr-resource)
      input.krypton-focus-handler(
        ref="input"
        type="text",
        @focus="onFocus",
        style="position: fixed;left: -9999px;top: -9999px;"
      )
</template>

<script>
import Zepto from 'zepto-webpack'
import { dynamicMapState } from '@/common/util/store'
import Events from '@/configuration/Events'

/**
 * @see KJS-2030  Replace #kryptonFocusHandler by a class to avoid ID collision
 *      and add ref to trigger focus on input.
 */
export default {
  name: 'KryptonTabHandler',
  inject: ['context'],
  props: {
    position: {
      type: String,
      default: 'last'
    }
  },
  data() {
    return {
      backwards: true,
      nativeEvent: true
    }
  },
  computed: {
    ...dynamicMapState('context.namespace', ['fieldsOrder'])
  },
  mounted() {
    // Start subscriptions
    this.$busOn(Events.krypton.field.focus, message => {
      if (
        message.name === this.position &&
        message.formId === this.context.formId
      ) {
        this.backwards = message.backwards
        this.nativeEvent = false
        this.$refs.input.focus()
      }
    })
  },
  methods: {
    onFocus(e) {
      if (this.nativeEvent) this.backwards = this.position === 'last'
      const backwards = this.backwards
      // Move the focus to the first field
      if (this.position === 'first' && !this.backwards) {
        this.$bus.$emit(Events.krypton.field.focus, {
          formId: this.context.formId,
          name: this.fieldsOrder[0],
          backwards: false
        })
        // Move the focus to the last field
      } else if (this.position === 'last' && this.backwards) {
        this.$bus.$emit(Events.krypton.field.focus, {
          formId: this.context.formId,
          name: this.fieldsOrder[this.fieldsOrder.length - 1],
          backwards: true
        })
      } else {
        const $focusableEls = Zepto(
          'input:not(.kr-input-field), select, button, textarea'
        )
        $focusableEls.each((index, $el) => {
          if ($el === e.target) {
            const nextIndex = !backwards ? index + 1 : index - 1
            $focusableEls[nextIndex]?.focus()
          }
        })
      }

      this.backwards = this.position === 'last'
      this.nativeEvent = true
    }
  }
}
</script>
