<template lang="pug">  
  .kr-smart-form-methods
    SmartFormEmbeddedHeader(v-if="renderEmbeddedHeader")
    SmartFormMethodsListLabel(
      :mode="isGroupingActivated ? 'groups' : 'methods'"
      :all-locked="paymentDone || hasAnyActiveMethod"
      :is-modal="false"
      v-on:group-selected="loadGroup"
      v-on:selected="setMethod"
      v-on:back="goBack"
    )
      template(v-slot:cardForm="")
        slot(name="cardForm")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormEmbeddedHeader from '@/host/components/smartform/EmbeddedHeader'
import SmartFormMethodsListLabel from '@/host/components/smartform/MethodsListLabel'
import SmartFormMethodsListContent from '@/host/components/smartform/MethodsListContent'

import { SmartFormMethodsList } from '@/host/components/mixins/SmartFormMethodsList'

export default {
  name: 'SmartFormEmbeddedMethodsList',
  components: {
    SmartFormEmbeddedHeader,
    SmartFormMethodsListLabel,
    SmartFormMethodsListContent
  },
  mixins: [SmartFormMethodsList],
  computed: {
    ...mapState(['amount']),
    ...mapState({
      paymentDone: state => state.smartForm.paymentDone,
      activeGroup: state => state.smartForm.activeGroup
    }),
    ...mapGetters([
      'cardsNotIntegrated',
      'hasAnyActiveMethod',
      'hasCardMethodAvailable',
      'hasSeveralPaymentMethods',
      'isGroupingActivated',
      'isWallet',
      'outsideCardsForm'
    ]),
    renderEmbeddedHeader() {
      return (
        !!this.amount &&
        this.hasSeveralPaymentMethods &&
        (!this.cardsNotIntegrated ||
          (this.cardsNotIntegrated && this.isWallet) ||
          !this.hasCardMethodAvailable ||
          this.outsideCardsForm)
      )
    }
  }
}
</script>
