import _ from 'underscore'

import clientDE from '@/configuration/i18n/client/de-DE.js'
import clientEN from '@/configuration/i18n/client/en-EN.js'
import clientAR from '@/configuration/i18n/client/es-AR.js'
import clientCL from '@/configuration/i18n/client/es-CL.js'
import clientCO from '@/configuration/i18n/client/es-CO.js'
import clientES from '@/configuration/i18n/client/es-ES.js'
import clientMX from '@/configuration/i18n/client/es-MX.js'
import clientPE from '@/configuration/i18n/client/es-PE.js'
import clientUY from '@/configuration/i18n/client/es-UY.js'
import clientFR from '@/configuration/i18n/client/fr-FR.js'
import clientIT from '@/configuration/i18n/client/it-IT.js'
import clientJP from '@/configuration/i18n/client/ja-JP.js'
import clientNL from '@/configuration/i18n/client/nl-NL.js'
import clientPL from '@/configuration/i18n/client/pl-PL.js'
import clientPT from '@/configuration/i18n/client/pt-PT.js'
import clientRU from '@/configuration/i18n/client/ru-RU.js'
import clientSE from '@/configuration/i18n/client/sv-SE.js'
import clientTR from '@/configuration/i18n/client/tr-TR.js'
import clientCN from '@/configuration/i18n/client/zh-CN.js'

export default {
  'zh-CN': clientCN,
  'tr-TR': clientTR,
  'sv-SE': clientSE,
  'ru-RU': clientRU,
  'pt-PT': clientPT,
  'pl-PL': clientPL,
  'nl-NL': clientNL,
  'ja-JP': clientJP,
  'it-IT': clientIT,
  'fr-FR': clientFR,
  'es-AR': clientAR,
  'es-CL': clientCL,
  'es-CO': clientCO,
  'es-ES': clientES,
  'es-MX': clientMX,
  'es-PE': clientPE,
  'es-UY': clientUY,
  'en-EN': clientEN,
  'de-DE': clientDE
}
