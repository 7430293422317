import { isFunction, find } from 'underscore'
import eventCallbacks from '@/configuration/sources/EventCallbacks.yml'

export default class CallbackHandler {
  constructor({ $bus, $store, logger }) {
    this.events = eventCallbacks
    this.$store = $store
    this.$bus = $bus
    this.logger = logger
    this.resetCallbacks()
  }

  resetCallbacks() {
    this.callbacks = this.events.reduce((acc, x) => {
      acc[x] = []
      return acc
    }, {})
  }

  removeEventCallbacks(eventName, callback) {
    return new Promise((resolve, reject) => {
      if (eventName) {
        if (this.events.indexOf(eventName) !== -1) {
          if (callback) {
            if (this.isCallbackAlreadyAdd(eventName, callback)) {
              this.removeCallback(eventName, callback)
              resolve()
            } else {
              reject(
                callback.toString() + " : doesn't exist on event " + eventName
              )
            }
          } else {
            this.removeCallbackByEvent(eventName)
            resolve()
          }
        } else {
          reject(eventName + " doesn't exist")
        }
      } else {
        this.removeAll()
        resolve()
      }
    })
  }

  async runCallbacks(eventName, args, formId = null) {
    const callbacks = this.callbacks[eventName]

    if (callbacks.length) {
      if (eventName !== 'onLog') {
        this.logger.log('info', `[${eventName}] callback called`, {
          callback: callbacks
        })
      }

      const results = new Array(callbacks.length)
      let idx = 0

      // Call
      for (const callback of callbacks) {
        results[idx] = await callback(args ? args : undefined)
        ++idx
      }

      return results
    }

    return []
  }

  /**
   * Add a callback
   * pre
   */
  addCallback(eventName, callback) {
    return new Promise((resolve, reject) => {
      if (isFunction(callback)) {
        if (eventName !== 'onLog')
          this.logger.log('info', `[${eventName}] callback registered`, {
            callback
          })
        if (this.isCallbackAlreadyAdd(eventName, callback)) {
          this.removeCallback(eventName, callback)
          // Events with only one callback allowed
        } else if (
          eventName === 'onError' ||
          eventName === 'onTransactionCreated' ||
          eventName === 'onSubmit'
        ) {
          this.removeCallbackByEvent(eventName)
        }
        this.callbacks[eventName].push(callback)
        resolve()
      } else {
        this.$store.dispatch('error', {
          errorCode: 'CLIENT_002',
          metadata: { eventName }
        })
        const { translate, onErrorTranslationLoaded } = this.$store.getters
        onErrorTranslationLoaded(() => {
          reject(translate('CLIENT_002').replace('%function%', eventName))
        })
      }
    })
  }

  /**
   * @private
   */
  isCallbackAlreadyAdd(eventName, callback) {
    return (
      find(
        this.callbacks[eventName],
        x => x.toString() === callback.toString()
      ) !== undefined
    )
  }

  /**
   * Remove all callback
   * @private
   */
  removeAll() {
    this.resetCallbacks()
  }

  /**
   * Remove all callback of an event
   * @private
   */
  removeCallbackByEvent(eventName) {
    const hadCallbacks = this.callbacks[eventName].length
    this.callbacks[eventName] = []
    if (hadCallbacks) {
      console.warn(`Existing ${eventName} callbacks have been removed`)
      if (eventName !== 'onLog')
        this.logger.log('info', `[${eventName}] callbacks removed`)
    }
  }

  /**
   * Remove a precise callback
   * @private
   */
  removeCallback(eventName, func) {
    const temp = this.callbacks[eventName].filter(
      x => x.toString() !== func.toString()
    )
    this.callbacks[eventName] = temp
    console.warn(`Existing ${eventName} callback's been removed`)
    if (eventName !== 'onLog')
      this.logger.log('info', `[${eventName}] callback removed`, {
        callback: func
      })
  }

  onSubmitEventListener() {
    // Adds a new interceptor to the stack
    this.$store.dispatch('addInterceptor', {
      name: 'onSubmit',
      promise: (paymentData, formId) => {
        return new Promise(async (resolve, reject) => {
          // If the callback result is not true (false any of the items), kill the execution
          const results = await this.runCallbacks(
            'onSubmit',
            paymentData,
            formId
          )
          if (!~results.indexOf(false)) resolve()
          else {
            this.$store.dispatch('update', { redirectTransaction: null })
            reject()
          }
        })
      }
    })
  }

  onTransactionCreatedEventListener() {
    // Adds a new interceptor to the stack
    this.$store.dispatch('addInterceptor', {
      name: 'onTransactionCreated',
      promise: (paymentData, formId) => {
        return new Promise(async (resolve, reject) => {
          // If the callback result is not true (false any of the items), kill the execution
          const results = await this.runCallbacks(
            'onTransactionCreated',
            paymentData,
            formId
          )
          if (!~results.indexOf(false)) resolve()
          else {
            this.$store.dispatch('update', { redirectTransaction: null })
            reject()
          }
        })
      }
    })
  }

  onClickEventListener() {
    // Adds a new interceptor to the stack
    this.$store.dispatch('addInterceptor', {
      name: 'paymentButton',
      promise: formId => {
        return new Promise(async (resolve, reject) => {
          // If the callback result is not true (false any of the items), kill the execution
          const results = await this.runCallbacks(
            'button.onClick',
            null,
            formId
          )
          if (!~results.indexOf(false)) resolve()
          else reject()
        })
      }
    })
  }

  onSmartFormClickEventListener() {
    // Adds a new interceptor to the stack
    this.$store.dispatch('addInterceptor', {
      name: 'onSmartFormClick',
      promise: methodInfo => {
        return new Promise(async (resolve, reject) => {
          // If the callback result is not true (false any of the items), kill the execution
          const results = await this.runCallbacks(
            'smartForm.onClick',
            methodInfo
          )
          if (!~results.indexOf(false)) resolve()
          else reject()
        })
      }
    })
  }

  onPaymentTokenDeletedEventListener() {
    // Adds a new interceptor to the stack
    this.$store.dispatch('addInterceptor', {
      name: 'onPaymentTokenDeleted',
      promise: tokenData => {
        return new Promise(async (resolve, reject) => {
          // If the callback result is not true (false any of the items), kill the execution
          const results = await this.runCallbacks(
            'wallet.onPaymentTokenDeleted',
            tokenData
          )
          if (!~results.indexOf(false)) resolve()
          else reject()
        })
      }
    })
  }
}
