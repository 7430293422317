<template lang="pug">
  .kr-field-element(
    v-show="isVisible",
    :class="dynamicClasses.element",
    kr-resource
  )
    .kr-field-wrapper(:class="dynamicClasses.wrapper", kr-resource)
      krypton-visibility-button(:data-name="fieldName" :field-name="fieldName")
      krypton-delete-button(v-if="activeToken && !isTokenForced", :location="fieldName", :method="`CARDS:${selectedBrand}`", :token="activeToken")
      krypton-help-button(:data-name="fieldName" :field-name="fieldName")
      krypton-icon(:data-name="fieldName" :field-name="fieldName")
      krypton-label(:data-name="fieldName" :field-name="fieldName")
      .kr-field-component(
        v-show="isVisible",
        :data-field-name="fieldName",
        :class="dynamicClasses.component",
        :style="configStyles"
      )
          .kr-iframe-wrapper(style="display:block; width:100%; height:100%;")
              div(style="display:none;", ref="slot-wrapper")
                  slot
              iframe(v-if="settedAtributes", v-bind="iframeAttributes")
</template>

<script>
import Events from '@/configuration/Events'
import { mapState } from 'vuex'
import { dynamicMapState, dynamicMapGetters } from '@/common/util/store'

/** COMPONENTS */
import KryptonVisibilityButton from '@/host/components/controls/KryptonVisibilityButton'
import KryptonIcon from '@/host/components/controls/KryptonIcon'
import KryptonHelpButton from '@/host/components/controls/KryptonHelpButton'
import KryptonDeleteButton from '@/host/components/controls/KryptonDeleteButton'

/** MIXINS */
import { FieldMixin } from '@/host/components/mixins/Field'

export default {
  name: 'KryptonField',
  components: {
    KryptonVisibilityButton,
    KryptonIcon,
    KryptonHelpButton,
    KryptonDeleteButton
  },
  mixins: [FieldMixin],
  inject: ['context'],
  props: {
    fieldName: { type: String, default: '' }
  },
  data() {
    return {
      iframeAttributes: {},
      settedAtributes: false,
      error: false
    }
  },
  computed: {
    ...mapState({
      formLayout: state => state.form.layout,
      configStyles(state) {
        const { innerDefault, innerError } = state.css[this.fieldName]
        return [innerDefault, this.error ? innerError : ''].map(
          x => x['background-color']
        )
      }
    }),
    ...dynamicMapState('context.namespace', ['walletMode', 'selectedBrand']),
    ...dynamicMapGetters('context.namespace', [
      'isFieldVisible',
      'isTokenForced',
      'activeToken'
    ]),
    isHalfSize() {
      return (
        ~['expiryDate', 'securityCode'].indexOf(this.fieldName) &&
        this.formLayout === 'compact' &&
        this.walletMode !== 'myCards' &&
        this.isFieldVisible('securityCode')
      )
    },
    dynamicClasses() {
      return {
        element: {
          'kr-error': this.isInvalid,
          'kr-disabled': this.isDisabled,
          'kr-size-half': this.isHalfSize,
          'kr-field-element--warning': this.applyWarningStyle
        },
        wrapper: {
          [this.wrapperClassName]: true
        },
        component: {
          'kr-disabled': this.isDisabled
        }
      }
    }
  },
  created() {
    this.startSubscriptions()
  },
  mounted() {
    if (this.$refs['slot-wrapper']?.children[0]?.innerHTML) {
      this.iframeAttributes = JSON.parse(
        this.$refs['slot-wrapper'].children[0].innerHTML
      )
    }
    this.settedAtributes = true
  },
  methods: {
    startSubscriptions() {
      this.$busOn(Events.krypton.field.event, ({ formId, name, type }) => {
        if (
          formId === this.context.formId &&
          name === this.fieldName &&
          type === 'keyPress'
        ) {
          this.error = false
        }
      })
    }
  }
}
</script>
