var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "kr-methods-group",
      class: { "kr-locked": _vm.isDisabled },
      attrs: { "kr-payment-method": _vm.name },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "onresize",
              rawName: "v-onresize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          ref: "activator",
          staticClass: "kr-methods-group-activator",
          class: _vm.dynamicClasses,
          attrs: { type: "button" },
          on: {
            click: _vm.openGroup,
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        [
          _vm.hasRadioButton
            ? _c("SmartFormRadioButton", {
                ref: "radioButton",
                attrs: { checked: _vm.isSelected, hover: _vm.hover },
              })
            : _vm._e(),
          _vm._l(_vm.icons.main, function (brand) {
            return _c("span", {
              ref: "icons",
              refInFor: true,
              staticClass: "kr-method-icon",
              class: "kr-" + brand.name,
              attrs: { "kr-payment-method": brand.name },
              domProps: { innerHTML: _vm._s(brand.icon) },
            })
          }),
          _c(
            "SmartFormMethodTooltip",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasOutstandingIcons,
                  expression: "hasOutstandingIcons",
                },
              ],
              ref: "tooltip",
              staticClass: "kr-extra-brands",
              attrs: { label: _vm.tooltipLabel, attach: !_vm.shouldDetach },
            },
            [
              _c(
                "div",
                { staticClass: "kr-methods-group-tooltip" },
                _vm._l(_vm.icons.outstanding, function (brand) {
                  return _c("span", {
                    staticClass: "kr-method-icon",
                    class: "kr-" + brand.name,
                    domProps: { innerHTML: _vm._s(brand.icon) },
                  })
                }),
                0
              ),
            ]
          ),
          _c(
            "label",
            {
              ref: "label",
              staticClass: "kr-methods-group-label",
              class: { "kr-locked": _vm.isDisabled },
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }