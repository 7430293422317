import { isObject } from 'underscore'
import { getParentDomain } from '@/common/util/location'
import payment from '@/common/util/payment'
import { calcTopLeftCorner } from '@/common/util/window'
import { MethodDescriptor } from '@/common/model/PaymentMethod'
import { closeListener, focusListener } from '../utils/window'

export const getWindowState = () => {
  return {
    hostViewport: {
      width: null,
      height: null
    }
  }
}

export const windowActions = ($locator, app) => {
  return {
    showWindow({ state, dispatch, getters }, method) {
      method = MethodDescriptor.create(method)

      let specs
      const { width, height } = getters.getMethodSize(method.name)
      // specs only for popup (tab need no specs)
      if (getters.getRedirectionType(method.name) === 'popup') {
        const { top, left } = calcTopLeftCorner({ width, height }, window)
        specs = `height=${height},width=${width},top=${top},left=${left},scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`
      }

      const jsonUrl = {
        applicationPath: state.applicationPath,
        parentDomain: getParentDomain()
      }

      const redirectUrl = `${
        state.baseAddress
      }/redirect/redirect.html?assets=%%build_number%%&language=${
        state.language
      }&isIframe=no#${encodeURIComponent(JSON.stringify(jsonUrl))}`

      if (!state.isUnitaryTest) {
        window.redirectionWindow = window.open(redirectUrl, '_blank', specs)
        if (getters.getRedirectionType(method.name) === 'popup') {
          window.redirectionWindow.resizeTo(width, height)
        }
      }

      dispatch('openMethod', method)
      dispatch('redirection', true)
      // Setup payment listener
      payment.messageListener($locator)

      closeListener(window.redirectionWindow, dispatch)
      focusListener(window.redirectionWindow)
    },
    closeWindow({ dispatch, state, getters }) {
      if (app === 'redirect') window.close()
      if (!window.redirectionWindow?.close) return
      window.redirectionWindow.close()
      window.redirectionWindow = null
      // If there is no transaction or error, throw an aborted error
      if (
        !state.redirectTransaction &&
        !state.error.errorCode &&
        !getters.hasDeadEndPaymentMethod()
      ) {
        dispatch('error', getters.getAbortedErrorCode)
      }
      dispatch('closeMethod')
    },

    // Should be only set in the host. Inside the iframes, the viewport refers to the iframe size
    setHostViewport({ commit, getters }) {
      const { width, height } = getters.getViewport()
      commit('UPDATE', { hostViewport: { width, height } })
    }
  }
}
export const windowMutations = {}
export const windowGetters = {
  getViewport: () => {
    return () => {
      // Fallback for browsers that don't support visualViewport
      const { width, height } = window.visualViewport || {
        width: window.innerWidth,
        height: window.innerHeight
      }
      return { width, height }
    }
  }
}
