var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-smart-form-methods" },
    [
      _vm.renderEmbeddedHeader ? _c("SmartFormEmbeddedHeader") : _vm._e(),
      _c("SmartFormMethodsListLabel", {
        attrs: {
          mode: _vm.isGroupingActivated ? "groups" : "methods",
          "all-locked": _vm.paymentDone || _vm.hasAnyActiveMethod,
          "is-modal": false,
        },
        on: {
          "group-selected": _vm.loadGroup,
          selected: _vm.setMethod,
          back: _vm.goBack,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "cardForm",
              fn: function () {
                return [_vm._t("cardForm")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }