import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import Events from '@/configuration/Events'

export default class ApplePayGetPayloadInfosTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'applePayGetPayloadInfos')
    this.browserRequests = $locator.browserRequests
  }

  async onEvent({ payload, testMode }) {
    try {
      await super.onEvent({ payload, testMode })
      const res = await this.callGetPayloadInfos(payload, testMode)
      const paymentToken = payload?.token ?? {}

      if (this.isValid(res)) {
        const { brands, bin } = res.response.answer

        // If there are dynamic installments we need to call setBin to get the proper binOptions
        if (this.$store.state.hasDynamicValues) {
          this.$store.dispatch(
            `cardForm_${this.$store.state.forms.main}/setBin`,
            bin
          )
        }

        // Get the selected brand or the first one
        const brand = this.getSelectedBrand(brands)

        // Setup the extras form
        this.$store.dispatch('setupExtrasForm', brand)
        if (!this.$store.getters.extrasFormHasData) {
          // Trigger apple pay payment action, no extra fields required
          this.$bus.$emit(Events.krypton.message.applePayPayment, {
            paymentToken
          })
        } else {
          // Send the payment token data to the ApplePayPaymentTrigger
          this.$bus.$emit(Events.ghost.applePay.paymentToken, {
            paymentToken
          })
          // Close the apple pay modal
          this.closeApplePayModal(testMode)
          // must set the brand to update installments select content
          this.$store.dispatch('updateActiveForm', { selectedBrand: brand })
          // The payment should be triggered from the modal
          this.$store.dispatch('showExtrasForm')
        }
      } else {
        this.onError(res.response.answer)
      }
    } catch (error) {
      this.onError(error)
    }
  }

  onError(error, path = 'ghost/service/ApplePayGetPayloadInfos.onEvent') {
    error.paymentMethod = 'APPLE_PAY'
    super.onError(error, path)
    this.$store.dispatch('unselectMethod')
    this.proxy.send(this.storeFactory.create('applePayGetPayloadInfosError'))
  }

  /**
   * Call the get payload infos WS
   * @param {Object} payload
   * @param {Boolean} testMode
   * @returns {Promise} response
   * e.g.
   * { response: { answer: { brands: ['VISA'] } } }
   */
  async callGetPayloadInfos(payload, testMode) {
    const { dna, publicKey, remoteId } = this.$store.state

    const objectData = {
      agreementUuid: dna.smartForm.APPLE_PAY.metadata.mids[0].agreementUuid,
      publicKey,
      payload: testMode ? this.$store.getters.getApplePayTestPayload : payload,
      payloadType: 'APPLE_PAY'
    }
    const url = this.restAPI.addJSessionID(
      this.restAPI.getGetApplePayPayloadInfos(this.endpoint)
    )

    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: { remoteId },
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }

  isValid(res) {
    return !(res.response.status === 'ERROR' || res.response.answer?.errorCode)
  }

  /**
   * Close the apple pay modal (real or simulator)
   * @param {Boolean} testMode
   */
  closeApplePayModal(testMode) {
    if (testMode) this.$store.dispatch('closeApplePaySimulatorModal')
    else this.proxy.send(this.storeFactory.create('applePayPaymentVerified'))
  }

  /**
   * Get the selected brand or the first one
   * @param {Array} brands
   * @returns {String} brand
   */
  getSelectedBrand(brands) {
    const brandIntersection = Object.keys(this.$store.state.dna.cards).filter(
      brandName => brands.includes(brandName)
    )

    return brandIntersection.length === 0 ? brands[0] : brandIntersection[0]
  }
}
