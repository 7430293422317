var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-smart-button-wrapper" },
    [
      (!_vm.isSyncReady && _vm.isVisible) || !_vm.hasValidToken
        ? _c("SmartButtonSkeleton")
        : _vm._e(),
      _vm.isSyncReady && _vm.isVisible
        ? _c(
            "div",
            {
              staticClass: "kr-smart-button kr-sm-internal",
              class: _vm.dynamicClasses,
              style: _vm.styles.button,
              attrs: {
                title: _vm.title,
                "kr-payment-method": _vm.cleanPaymentMethod.toLowerCase(),
              },
              on: {
                click: _vm.openCard,
                mouseover: function ($event) {
                  _vm.hover = true
                },
                mouseleave: function ($event) {
                  _vm.hover = false
                },
              },
            },
            [
              !_vm.spinnerVisible || !_vm.isLoading
                ? _c("div", { staticClass: "kr-method-info" }, [
                    _vm.icon
                      ? _c("div", {
                          staticClass: "kr-method-icon",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getCustomPaymentMethodIcon(_vm.method) ||
                                _vm.icon
                            ),
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "kr-label" }, [
                      _c(
                        "label",
                        { staticClass: "kr-method-label forced-payment-token" },
                        [_vm._v(_vm._s(_vm.methodLabel))]
                      ),
                      _c("label", { staticClass: "kr-amount-label" }, [
                        _vm._v(_vm._s(_vm.amountLabel)),
                      ]),
                    ]),
                    _vm.applePaySimulator
                      ? _c("span", { staticClass: "kr-method-badge" }, [
                          _vm._v("Simulator"),
                        ])
                      : _vm.isPayPalSandbox
                      ? _c("span", { staticClass: "kr-method-badge" }, [
                          _vm._v("Sandbox"),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.spinnerVisible && _vm.isLoading
                ? _c("div", {
                    staticClass: "kr-method-info",
                    class: { "kr-hidden": _vm.spinnerVisible && _vm.isLoading },
                  })
                : _vm._e(),
              _vm.spinnerVisible && _vm.isLoading
                ? _c("KryptonLoadingAnimation")
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("krypton-layer", { attrs: { mode: "unified", method: _vm.method } }),
      _c("SmartButtonError", { attrs: { reference: _vm.reference } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }