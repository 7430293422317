<template lang="pug">
    button.kr-payment-button(
        kr-resource
        type="button"
        v-show="isVisible"
        :class="dynamicClasses"
        :style="styles"
        :disabled="isDisabled"
        v-html="payBtnHTML"
        @click="btnClick"
        @keyup.9="tabReport"
        @keydown.9="tabReport"
        tabindex=1
    )
</template>

<script>
import { FormPaymentButtonMixin } from '@/host/components/mixins/FormPaymentButton'
import { mapGetters } from 'vuex'

export default {
  name: 'ExtrasPaymentButton',
  mixins: [FormPaymentButtonMixin],
  computed: {
    ...mapGetters(['isExtrasFormVisible']),
    isLoading() {
      return (
        !this.hasError &&
        (this.processingPayment === true || this.spinnerVisible) &&
        this.isExtrasFormVisible
      )
    }
  },
  methods: {
    btnClick(e) {
      e.preventDefault()
      if (!this.isDisabled) {
        this.disableButton()
        this.cleanError()

        this.$proxy.send(
          this.$storeFactory.create('applePayPayment', { paymentToken: {} })
        )
        this.focusHelper()
      }
    }
  }
}
</script>
