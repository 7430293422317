var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "kr-brand-icon",
    class: [
      "kr-" + _vm.method.toLowerCase(),
      _vm.isCards ? "kr-cards" : "",
      _vm.assetsLoading ? "kr-loading-assets" : "",
    ],
    domProps: { innerHTML: _vm._s(_vm.iconHtml) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }