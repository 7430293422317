import Events from '@/configuration/Events'
import { getApplePayFormattedAmount } from '@/common/service/CurrencyFormatter'

export default class ApplePayManager {
  constructor({ $store, $bus, proxy, storeFactory }) {
    this.$store = $store
    this.$bus = $bus
    this.proxy = proxy
    this.storeFactory = storeFactory
  }

  static canStartSession() {
    return typeof ApplePaySession === 'function'
  }

  /**
   * Initiates apple pay payment
   */
  initialize() {
    this.setupSession()
    this.setupListeners()
  }

  start() {
    this.session.begin()
  }

  /**
   * Sets up the session using the API
   */
  setupSession() {
    const { amount, currency, language, country, shopName } = this.$store.state
    this.session = new ApplePaySession(3, {
      countryCode: country,
      currencyCode: currency,
      // TODO - Filter based on DNA data (can't be exactly the same)
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: shopName,
        amount: getApplePayFormattedAmount(amount, currency, language)
      }
    })
  }

  /**
   * Sets up the listeners for the whole payment workflow
   */
  setupListeners() {
    this.onCancel()
    this.onValidate()
    this.onAuthorized()
    this.onError()
  }

  onCancel() {
    this.session.oncancel = event => {
      const error = { errorCode: 'CLIENT_101', paymentMethod: 'APPLE_PAY' }
      this.$store.dispatch('error', error) // Throw an abort error
      this.$store.dispatch('applePayRealModalClosed')
    }
  }

  onValidate() {
    this.session.onvalidatemerchant = ({ validationURL }) => {
      // Setup the applePay session creation listener
      this.$bus.$once(
        Events.krypton.message.applePayMerchantSession,
        ({ merchantSession }) => {
          this.session.completeMerchantValidation(merchantSession)
          this.$store.dispatch('applePayRealModalOpened')
        }
      )
      // Trigger the session creation
      this.proxy.send(
        this.storeFactory.create('getApplePayToken', {
          validationURL
        })
      )
    }
  }

  onAuthorized() {
    this.session.onpaymentauthorized = ({ payment }) => {
      // Listen to the payment event
      this.$bus.$once(
        [
          Events.krypton.message.payment,
          Events.krypton.message.applePayPaymentVerified
        ],
        msg => {
          // Trigger the successful payment on the API
          this.session.completePayment({
            status: ApplePaySession.STATUS_SUCCESS
          })
          this.$store.dispatch('applePayRealModalClosed')
        }
      )

      // Applepay payment will trigger from the applePayGetPayloadInfos or the extra fields modal
      this.proxy.send(
        this.storeFactory.create('applePayGetPayloadInfos', {
          payload: payment,
          testMode: false
        })
      )
    }
  }

  onError() {
    this.$bus.$once(Events.krypton.message.abortApplePaySession, () => {
      this.session.abort()
      this.$store.dispatch('applePayRealModalClosed')
    })
    this.$bus.$once(Events.krypton.message.applePayPaymentError, () => {
      this.session.completePayment({
        status: ApplePaySession.STATUS_SUCCESS
      })
      this.$store.dispatch('applePayRealModalClosed')
    })
  }
}
