var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.headerVisible,
          expression: "headerVisible",
        },
      ],
      staticClass: "kr-smart-form-embedded-header",
      style: _vm.styles.wrapper,
    },
    [
      _c("span", { staticClass: "kr-amount-label" }, [
        _vm._v(_vm._s(_vm.translate("amount"))),
      ]),
      _c(
        "span",
        { staticClass: "kr-amount-figure", style: _vm.styles.amount.figure },
        [_vm._v(_vm._s(_vm.amountLabel))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }