var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-smart-form-wallet-card kr-method-label",
      class: _vm.dynamicClasses,
      attrs: {
        "data-card": _vm.pan,
        "data-token": _vm.card.token,
        type: "button",
      },
      on: {
        click: _vm.clickHandler,
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            attrs: {
              checked: _vm.isSelected,
              hover: _vm.hover || _vm.hoverDelete,
            },
          })
        : _vm._e(),
      _c("BrandIcon", { attrs: { method: _vm.card.brand } }),
      _c("SmartFormWalletCardLabel", {
        attrs: { value: _vm.formatCardNumber(_vm.pan, _vm.card.brand) },
      }),
      !_vm.showLoading
        ? _c("KryptonDeleteButton", {
            attrs: { token: _vm.card.token, method: "CARDS:" + _vm.card.brand },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onClick($event)
              },
              mouseover: function ($event) {
                $event.stopPropagation()
                _vm.hoverDelete = true
              },
              mouseleave: function ($event) {
                $event.stopPropagation()
                _vm.hoverDelete = false
              },
            },
          })
        : _c("KryptonLoadingAnimation"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }