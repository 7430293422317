<template lang="pug">  
.kr-smart-form-modal-wrapper(v-show="open")
  .kr-smart-form-modal(:class="dynamicClasses.modal")
    KryptonPopinHeader(v-on:close="closeModal()", :is-smart-form="true")
    .kr-smart-form-modal-views-container(:class="dynamicClasses.viewsContainer", :style="dynamicStyles.viewsContainer")
      .kr-smart-form-modal-view(
        v-for="(_, name) in $scopedSlots",
        :class="getViewClasses(name)",
      )
        slot(:name="name")
    KryptonPopinFooter
</template>

<script>
import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonPopinFooter from '@/host/components/popin/KryptonPopinFooter'
import { mapActions, mapGetters, mapState } from 'vuex'
import { defer, delay } from 'underscore'
import { PopinSizeMixin } from '@/host/components/mixins/PopinSize'

export default {
  name: 'SmartFormModal',
  components: {
    KryptonPopinHeader,
    KryptonPopinFooter
  },
  mixins: [PopinSizeMixin],
  props: {
    view: {
      type: String,
      default: 'default'
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardsView: false,
      isOpen: false
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen']),
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || []
    }),
    ...mapGetters([
      'isSmartFormPopin',
      'isCardMethodActive',
      'isSmallScreen',
      'requireFullScreenRedirect',
      'isWalletSmartForm',
      'cardsNotIntegrated'
    ]),
    activeLevel() {
      return Object.keys(this.$scopedSlots).indexOf(this.view)
    },
    dynamicClasses() {
      const classes = {
        background: {
          'kr-modal-open': this.isOpen
        },
        modal: {
          'kr-modal-open': this.isOpen,
          'kr-secure-iframe-visible': this.redirectIframeOpen,
          'kr-mobile': this.isSmallScreen,
          'kr-secure-iframe-open': this.redirectIframeOpen,
          'kr-fullscreen':
            this.redirectIframeOpen && this.requireFullScreenRedirect
        },
        viewsContainer: {
          'kr-smart-form-modal-view--select-open': this.isAnySelectOpen
        }
      }
      if (this.cardsView) {
        classes.viewsContainer = {
          ...classes.viewsContainer,
          'kr-smart-form-modal-view-cards': true,
          'kr-secure-iframe-visible': this.redirectIframeOpen
        }
      }
      return classes
    },
    dynamicStyles() {
      const classes = {
        viewsContainer: {}
      }
      if (this.redirectIframeOpen) {
        const { width } = this.popinSize
        classes.viewsContainer = { width: `${width}px` }
      }
      return classes
    },
    walletMode() {
      return this.isWalletSmartForm && this.cardTokens.length > 0
    },
    isAnySelectOpen() {
      const { forms } = this.$store.state
      const formId =
        this.walletMode && this.cardsNotIntegrated && forms.clone
          ? forms.clone
          : forms.main
      if (!this.$store.hasModule([`cardForm_${formId}`])) {
        return false
      }
      const { selectFields } = this.$store.state[`cardForm_${formId}`]
      return Object.values(selectFields).some(status => status)
    }
  },
  watch: {
    open(val) {
      defer(() => {
        if (this.open) {
          this.isOpen = this.open
        }
      })
    },
    isOpen(val) {
      if (val) return
      delay(() => {
        if (!this.isOpen) {
          this.closeSmartFormPopin()
        }
      }, 100)
    },
    isCardMethodActive(val) {
      if (val)
        delay(() => {
          this.cardsView = val
        }, 250)
      else this.cardsView = val
    }
  },
  methods: {
    ...mapActions(['closeSmartFormPopin']),
    closeModal() {
      this.isOpen = false
    },
    getViewClasses(view) {
      return {
        [`kr-smart-form-modal-view--level-${this.activeLevel}`]: true,
        [`kr-smart-form-modal-view-${view}`]: true,
        'kr-smart-form-modal-view--active': this.view === view,
        'kr-smart-form-modal-view--popin': this.isSmartFormPopin
      }
    }
  }
}
</script>
