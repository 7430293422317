<template lang="pug">
.kr-input-relative-wrapper(:style="fieldCss.inputRelativeWrapper")
  .kr-input-relative-wrapper-inner
    input#inputField.kr-input-field(
      ref="input"
      @keyup.9="tabReport"
      @keydown.9="tabReport"
      :disabled="isDisabled || !ready"
      autocorrect="off"
      autocomplete="cc-csc"
      spellcheck="false"
      tabindex="1"
      :class="{'kr-password': securedCvv && inputValue, 'kr-on-error': hasError, 'on-error-animation': errorAnimation}"
      :placeholder="placeholder"
      :style="inputCSS"
      :type="type"
      :pattern="pattern"
      v-model="inputValue"
      :aria-label="fieldLabel"
      :aria-invalid="hasError ? 'true' : 'false'"
      :required="required"
    )
    // Preload password font
    input.kr-password(
      :style="{ display: 'block', position: 'absolute', top: '-999px', left: '-999px' }",
      value="123",
      aria-hidden="true"
    )
    CursorMirror(:input-value="inputValueCaret", ref="caret", v-if="!isUIWebView")
    input.kr-focus-hunter(
      v-if="isIos"
      tabindex="-1"
      aria-hidden="true"
      :pattern="pattern"
      style="position:fixed;top:0;left:0;width:0px;height:0px;background-color:transparent;border:0px solid transparent;padding:1px;;display:inline-block;"
    )
</template>

<script type="text/javascript">
import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapGetters, dynamicMapState } from '@/common/util/store'

import { StyleMixin } from '@/slave/components/mixins/Style'
import { ClearMixin } from '@/slave/components/mixins/Clear'
import { KeyboardTypeMixin } from '@/slave/components/mixins/KeyboardType'
import { FocusHelperMixin } from '@/slave/components/mixins/FocusHelper'
import { FocusReporterMixin } from '@/slave/components/mixins/FocusReporter'
import { SecurityCodeInputEditionMixin } from '@/slave/components/mixins/InputEdition'
import {
  KeysMixin,
  BlurMixin,
  FocusMixin,
  PreventEventsMixin,
  EventsMixin
} from '@/slave/components/mixins/Event'
import { PlaceholderMixin } from '@/slave/components/mixins/Placeholder'
import { FieldLabelMixin } from '@/slave/components/mixins/FieldLabel'
import { SecurityCodeValidator } from '@/slave/components/mixins/Validator'
import { ShakeMixin } from '@/slave/components/mixins/Shake'
import { TestCardsMixin } from '@/slave/components/mixins/TestCards'
import { PaymentTokenMixin } from '@/slave/components/mixins/PaymentToken'
import { RequiredMixin } from '@/slave/components/mixins/Required'
import { StateMixin } from '@/slave/components/mixins/State'
import Events from '@/configuration/Events'
import CursorMirror from '@/slave/components/CursorMirror'

// Expiry date component
export default {
  /*rMirror
   * Name of the component and the html tag as : <SecurityCode />
   */
  name: 'SecurityCode',
  components: { CursorMirror },
  mixins: [
    BlurMixin,
    ClearMixin,
    FocusMixin,
    PreventEventsMixin,
    EventsMixin,
    KeyboardTypeMixin,
    FocusHelperMixin,
    FocusReporterMixin,
    SecurityCodeInputEditionMixin,
    KeysMixin,
    PlaceholderMixin,
    FieldLabelMixin,
    SecurityCodeValidator,
    ShakeMixin,
    StyleMixin,
    TestCardsMixin,
    PaymentTokenMixin,
    RequiredMixin,
    StateMixin
  ],
  inject: ['context'],
  data() {
    return {
      fieldName: 'securityCode',
      inputValueCaret: '',
      inputValue: '',
      prevValue: '',
      settedValue: false
    }
  },
  computed: {
    ...mapGetters(['isIos']),
    ...mapState('field', ['name', 'required', 'isUIWebView', 'ready']),
    ...mapState(['css']),
    ...dynamicMapGetters('context.namespace', ['isFieldVisible']),
    ...dynamicMapState('context.namespace', [
      'testCard',
      'visibleFields',
      'cvvSize',
      'selectedBrand',
      'securedCvv'
    ]),
    ...mapState('field', {
      autofill: state => state.autofill.securityCode
    }),
    cvvPattern() {
      return this.cvvSize === 4 ? '####' : '###'
    }
  },
  watch: {
    selectedBrand(newVal) {
      this.$store.dispatch(`${this.context.namespace}/update`, {
        cvvSize: newVal === 'AMEX' ? 4 : 3
      })
    },
    inputValue(newVal, oldVal) {
      if (newVal.length) this.cleanPlaceholder()
      this.inputValueCaret = newVal
      let cleanValue = newVal.replace(/\s/g, '')

      this.$bus.$emit(Events.slave.field.change, {
        cleanValue,
        value: newVal,
        oldValue: oldVal,
        el: this.$refs.input
      })
      this.hasError = false
    },
    /**
     * When changing the cvv Restriction, then the new
     * size will erase the previous value if it is longer
     * than the new restriction
     */
    cvvSize(newVal) {
      // Remove the field value if more chars than allowed
      let currentValue = this.inputValue
      if (currentValue.length > newVal) this.inputValue = ''
    },
    visibleFields(newVal, oldVal) {
      const isVisible = this.isFieldVisible('securityCode')
      if (!isVisible) {
        const _this = this
        this.inputValue = ''
        setTimeout(() => {
          _this.$refs.caret.cursorRedraw()
        }, 1)
      }
      this.$store.dispatch('field/update', { required: this.isRequired() })
    },
    testCard(newVal) {
      if (newVal) {
        this.settedValue = true
        // Check if it's disabled
        if (!this.isDisabled) {
          // Restrict the value to the max of cvvLength
          let cvvValue = this.getSecurityCodeValue()
          this.cleanPlaceholder()
          this.inputValue = cvvValue
        }
      }
    },
    autofill(newVal) {
      if (newVal) this.inputValue = newVal
      this.fieldEvent('permanentFocus')
    }
  }
}
</script>
