import Vue from 'vue'
import _ from 'underscore'
import LyraSentryClient from '@lyra-network/lyra-common-sentry-client/dist/lyra-sentry-client.minimal.esm.js'
import { defaultIntegrations } from '@sentry/browser'

import SentryDomains from '@/configuration/sources/SentryDomains.yml'
import SentryConf from '@/configuration/sources/Sentry.yml'

let sentryHub, shopId

const reportEnabled = () => {
  return process.env.NODE_ENV === 'production-sentry' && sentryHub !== null
}

export const setupSentry = app => {
  if (reportEnabled()) {
    const sentryConfig = {
      Vue,
      unique: app === 'host',
      dsn: '%%sentryurl%%',
      release: '%%library_version%%',
      dist: '%%build_number%%',
      environment: '%%sentry_environment%%',
      autoSessionTracking: false, // disable session tracking (one per load)
      tracingOptions: {
        trackComponents: true
      },
      integrations: [...defaultIntegrations],
      beforeSend(event) {
        // Custom domain filter
        if (event.level === 'error') {
          if (event.request?.url) {
            const url = event.request.url
            for (const reg of SentryDomains.blacklisted) {
              const rgx = new RegExp(reg, 'g')
              if (rgx.test(url)) {
                return null
              }
            }
          }
        }

        return event
      }
    }
    // For host environment, only certain domains are allowed
    if (app === 'host') sentryConfig['allowUrls'] = SentryDomains.whitelisted
    try {
      sentryHub = LyraSentryClient.init(sentryConfig)
      setLocationData()
    } catch (error) {
      sentryHub = null
      console.error(`[KJS][${app}] Sentry could not be initialized`)
      console.error(error)
    }
  }
}

export const logSentry = (prefix, message, byShopId = true) => {
  if (reportEnabled()) {
    // Report only for specific shops
    const shopIdList = []
    if (!byShopId || ~shopIdList.indexOf(shopId)) {
      if (_.isObject(message)) message = JSON.stringify(message)
      if (_.isObject(prefix)) prefix = JSON.stringify(prefix)
      sentryHub.run(currentHub => {
        currentHub.captureMessage(`${prefix} : ${message}`)
      })
    }
  }
}

export const logSentryException = (error, location, details = null) => {
  if (reportEnabled()) {
    sentryHub.run(currentHub => {
      currentHub.setExtra('caught', location)
      if (details) currentHub.setExtra('details', details)
      if (_.isObject(error) && error.type === 'error') {
        currentHub.setTag('errorCode', error.errorCode)
        currentHub.setTag('errorMessage', error.errorCode)
        currentHub.setTag('detailedErrorCode', error.detailedErrorCode)
        currentHub.setTag('detailedErrorMessage', error.detailedErrorMessage)
        const answer = error.metadata?.answer
        currentHub.setTag('orderStatus', answer?.clientAnswer?.orderStatus)
        currentHub.setTag('orderCycle', answer?.clientAnswer?.orderCycle)
        currentHub.setTag('serverDate', answer?.clientAnswer?.serverDate)
        currentHub.setTag(
          'orderId',
          answer?.clientAnswer?.orderDetails?.orderId
        )
        currentHub.setExtra('children', error.children)
        if (!_.isEmpty(error.metadata)) {
          if (error.metadata?.formToken) delete error.metadata.formToken
          currentHub.setExtra('metadata', error.metadata)
        }

        // Group errors based on their code
        currentHub.getScope().setFingerprint([error.errorCode])
        currentHub.captureException(
          new Error(`${error.errorCode} : ${error.errorMessage}`)
        )
      } else if (_.isString(error)) {
        currentHub.captureException(new Error(error))
      } else if (error instanceof Error) {
        currentHub.captureException(error)
      }
    })
  }
}

export const setSentryBasicInfo = state => {
  if (reportEnabled()) {
    const updateObj = JSON.parse(JSON.stringify(state))
    const allowed = _.union(SentryConf.tags, SentryConf.extras)
    const intProps = _.intersection(Object.keys(updateObj), allowed)
    if (intProps.length) {
      sentryHub.run(currentHub => {
        for (const prop of intProps) {
          if (prop in updateObj) {
            if (prop === 'formToken' && _.isString(updateObj[prop]))
              updateObj[prop] = updateObj[prop].substr(0, 16)
            if (prop === 'shopId') shopId = updateObj[prop]

            if (~SentryConf.tags.indexOf(prop))
              currentHub.setTag(prop, updateObj[prop])
            else currentHub.setExtra(...cleanData(prop, updateObj[prop]))
          }
        }
      })
    }
  }
}

const cleanData = (prop, data) => {
  if (prop === 'appLoadTimeData') {
    for (const item of data) {
      delete item.start
      delete item.end
      delete item.hide
      delete item.name
    }
  }

  return [prop, data]
}

export const setSentryTag = (tagName, tagValue) => {
  if (reportEnabled()) {
    sentryHub.run(currentHub => {
      currentHub.setTag(tagName, tagValue)
    })
  }
}

export const setSentryExtra = (extraName, extraValue) => {
  if (reportEnabled()) {
    sentryHub.run(currentHub => {
      currentHub.setExtra(extraName, extraValue)
    })
  }
}

export const setDeviceData = ({ state }) => {
  if (reportEnabled()) {
    const { browser, os } = state
    sentryHub.run(currentHub => {
      currentHub.setContext('browser', browser)
      currentHub.setContext('os', os)
    })
  }
}

const setLocationData = () => {
  setSentryTag('url', `${location.origin}${location.pathname}`)
  setSentryExtra('href', location.href)
}
