import _ from 'underscore'
import extend from 'deep-extend'
import { getSupported } from '@/common/util/i18n'

import { getConfigState } from '@/store/modules/config'
import { getFormState } from '@/store/modules/form'
import { getPopinState } from '@/store/modules/popin'
import { getIosState } from '@/store/modules/ios'
import { getCssState } from '@/store/modules/css'
import { getRedirectState } from '@/store/modules/redirect'
import { getErrorState } from '@/store/modules/error'
import { getEnvironmentState } from '@/store/modules/environment'
import { getSmartFormState } from '@/store/modules/smartform'
import { getNavigationState } from '@/store/modules/navigation'
import { getWindowState } from '@/store/modules/window'
import { getInternalsState } from '@/store/modules/internals'
import { getApplePayState } from '@/store/modules/applePay'
import { getToolbarState } from '@/store/modules/toolbar'
import { getExtrasFormState } from '@/store/modules/extrasForm'
import { getDomState } from '@/store/modules/dom'
import { getAmountState } from '@/store/modules/amount'
import { getWalletState } from '@/store/modules/wallet'
import { getBinOptionsState } from '@/store/modules/binOptions'

import tokens from '@/configuration/sources/Tokens.yml'

// root state (General properties)
export default app => {
  // Do not remove, we need deep extension
  return extend(
    {
      application: app,
      libraryVersion: '%%library_version%%',
      baseAddress: '',
      baseDomain: '',
      merchantDomain: '',
      applicationPath: '',
      endpoint: null,
      endpointDomain: null,
      whitelabel: '',
      formToken: tokens.noToken,
      initialFormToken: null,
      prefixedDemoToken: false,
      dna: null,
      parsingToken: false,
      publicKey: null,
      testKeys: false,
      language: getSupported(
        window?.navigator?.language ||
          window?.navigator?.userLanguage ||
          'en-EN'
      ),
      initialAmount: '',
      amount: '',
      amountLabel: '',
      updating: false,
      shopId: null,
      orderId: '',
      shopName: '',
      currency: '',
      country: '',
      hasInterests: false,
      remoteId: null,
      serverDateTime: null,
      hiddenToolbar: false,
      timeEnd: null,
      timeStart: null,
      networkLoadTimeData: [],
      appLoadTimeData: [],
      totalAppLoadTime: null,
      interceptors: {
        paymentButton: [],
        onSubmit: [],
        onTransactionCreated: [],
        onSmartFormClick: [],
        onPaymentTokenDeleted: []
      },
      riskAnalyser: null,
      helpPosition: 'inner-field',
      isUnitaryTest: false,
      allIFramesReady: false,
      synced: false, // initial sync done
      dnaTestCards: [],
      zIndex: null,
      triggerEvent: null,
      formTokenConsumed: false,
      processingPayment: false,
      experimental: false,
      renderMode: 'default' // or 'addForm|attachForm'
    },
    getConfigState(),
    getFormState(),
    getPopinState(),
    getIosState(),
    getCssState(),
    getRedirectState(),
    getErrorState(),
    getEnvironmentState(),
    getSmartFormState(),
    getNavigationState(),
    getWindowState(),
    getInternalsState(),
    getApplePayState(),
    getToolbarState(),
    getExtrasFormState(),
    getDomState(),
    getAmountState(),
    getWalletState(),
    getBinOptionsState()
  )
}
