import { getSmartButtonElements } from '../../common/util/dom'

export default class FormCleaner {
  allowedClassNames = ['kr-embedded', 'kr-smart-form', 'kr-smart-button']

  errors
  $validElements
  smartformFound
  embeddedFound

  constructor() {}

  validateElements($elements) {
    this.reset()
    $elements.forEach($element => this.validate($element))
    return { errors: this.errors, $validElements: this.$validElements }
  }

  reset() {
    this.errors = []
    this.$validElements = []
    this.smartformFound = false
    this.embeddedFound = false
  }

  validate($element) {
    // Must be HTML element
    if (!($element instanceof HTMLElement)) {
      // throw error element must be HTMLElement CLIENT_717
      this.errors.push('CLIENT_716')
      return
    }

    const foundClasses = this.getKrFormClasses($element)

    if (foundClasses.length > 1) {
      // element must only have exactly 1 of the required classes CLIENT_718
      this.errors.push('CLIENT_716')
      return
    } else if (foundClasses.length === 0) {
      // No classes found, check if element is a parent element
      this.parseParentElement($element)
      return
    } else {
      const foundClass = foundClasses[0]

      if (foundClass === 'kr-smart-form') {
        if (this.smartformFound) {
          this.errors.push('CLIENT_718')
          return
        }
        this.smartformFound = true
      } else if (foundClass === 'kr-embedded') {
        if (this.embeddedFound) {
          this.errors.push('CLIENT_718')
          return
        } else {
          this.embeddedFound = true
        }
      }

      this.$validElements.push($element)
    }
  }

  parseParentElement($element) {
    let somethingFound = false

    const $smartButtons = getSmartButtonElements($element)
    if ($smartButtons?.length > 0) {
      somethingFound = true
      this.$validElements.push(...$smartButtons)
    }

    // no form elements previously found
    if (!this.smartformFound && !this.embeddedFound) {
      // search for the first appearing form element
      const $firstFormElement = $element.querySelector(
        '.kr-smart-form, .kr-embedded'
      )
      if ($firstFormElement) {
        somethingFound = true
        this.$validElements.push($firstFormElement)

        // Get class Name
        const foundClass = this.getKrFormClasses($firstFormElement)[0]
        if (foundClass === 'kr-smart-form') {
          this.smartformFound = true

          // search possible embedded inside of smartform
          const $embeddedInside =
            $firstFormElement.querySelector('.kr-embedded')
          if (!$embeddedInside) {
            // If not found inside it could appear outside
            const $embeddedOutside = $element.querySelector('.kr-embedded')
            if ($embeddedOutside) {
              this.embeddedFound = true
              this.$validElements.push($embeddedOutside)
            }
          } else {
            // If inside we set the flag but dont add it to this.$validElements as its already part of it (inside smartform)
            this.embeddedFound = true
          }
        } else if (foundClass === 'kr-embedded') {
          this.embeddedFound = true

          // Search for a possible smartform element
          const $dettachedSmartForm = $element.querySelector('.kr-smart-form')
          if ($dettachedSmartForm) {
            this.smartformFound = true
            this.$validElements.push($dettachedSmartForm)
          }
        }
      }
    } else if (this.smartformFound && !this.embeddedFound) {
      const $embedded = $element.querySelector('.kr-embedded')
      if ($embedded) {
        somethingFound = true
        this.embeddedFound = true
        this.$validElements.push($embedded)
      }
    } else if (this.embeddedFound && !this.smartformFound) {
      const $smartform = $element.querySelector('.kr-smart-form')
      if ($smartform) {
        this.smartformFound = true
        somethingFound = true
        this.$validElements.push($smartform)
      }
    }

    if (!somethingFound) {
      // throw error, nothing found in this parent element
      this.errors.push('CLIENT_719')
    }
  }

  getKrFormClasses($element) {
    return $element.className
      .split(' ')
      .filter(cssClass => this.allowedClassNames.includes(cssClass))
  }

  addKRElement($elements) {
    $elements.forEach($element => $element.setAttribute('kr-element', ''))
  }
}
