import { getSupported } from '@/common/util/i18n'
import tokens from '@/configuration/sources/Tokens.yml'

/**
 * Applies a casting defined for the given value
 */
export const castValue = (locator, value, attribute, casting = null) => {
  if (!casting) return value

  switch (casting) {
    case 'int':
    case 'integer':
      return parseInt(value, 10)
    case 'string':
      return `${value}`
    case 'bool':
    case 'boolean':
      // Casting only if number
      if (typeof value == 'number') value = `${value}`
      if (typeof value !== 'string') return value
      if (value.toLowerCase() == 'true') return true
      if (value.toLowerCase() == 'false') return false
      if (`${value}` === '1') return true
      if (`${value}` === '0') return false
      break
    case 'csv':
      return value.split(',')
    case 'hasAttribute':
      return value || value === '' ? true : false
    case 'hasNoAttribute':
      return value || value === '' ? false : true
    case 'language':
      return getSupported(value)
    case 'popin':
      const isPopin = castValue(locator, value, attribute, 'hasAttribute')
      return isPopin ? 'popin' : 'embedded'
    case 'formToken':
      const currentFormToken = locator.$store.state.formToken
      if (currentFormToken !== tokens.noToken) return currentFormToken
      const token = locator.tokenReader.verifyToken(value)
      locator.tokenReader.setWhiteLabel(token)
      return token
  }

  return value
}
