import { mapGetters, mapState } from 'vuex'

import { SmartFormThemeMixin } from '@/host/components/mixins/SmartFormTheme'

export const GlobalThemeMixin = {
  mixins: [SmartFormThemeMixin],
  computed: {
    ...mapGetters(['isSmartFormCompact'])
  },
  watch: {
    isSmartFormCompact: 'updateDynamicClasses'
  },
  mounted() {
    this.updateDynamicClasses()
  },
  methods: {
    /**
     * if embedded and smartForm.layout it compact, then add
     * kr-smart-form--compact class
     *
     * @since KJS-2355
     */
    updateDynamicClasses() {
      const method = this.isSmartFormCompact ? 'add' : 'remove'
      this.$el.classList[method]('kr-smart-form--compact')
    }
  }
}
