var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hasDetached,
          expression: "!hasDetached",
        },
      ],
      staticClass: "kr-discount-panel-wrapper",
      attrs: { "kr-resource": "" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCardFormVisible(_vm.context.formId),
              expression: "isCardFormVisible(context.formId)",
            },
          ],
          staticClass: "kr-discount-panel",
          class: _vm.dynamicClasses.panel,
        },
        [
          _c(
            "div",
            {
              ref: "content",
              staticClass: "kr-discount-panel-content",
              class: _vm.dynamicClasses.content,
            },
            [
              _c("div", { staticClass: "kr-discount-panel-subtotal" }, [
                _c(
                  "span",
                  { staticClass: "kr-discount-panel-subtotal-label" },
                  [_vm._v(_vm._s(_vm.translate("discount_subtotal")) + ":")]
                ),
                _c(
                  "span",
                  { staticClass: "kr-discount-panel-subtotal-value" },
                  [_vm._v(_vm._s(_vm.getAmountLabel(_vm.subtotal)))]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "kr-discount-panel-discount",
                  class: _vm.dynamicClasses.discount,
                },
                [
                  _c(
                    "span",
                    { staticClass: "kr-discount-panel-discount-label" },
                    [_vm._v(_vm._s(_vm.discountLabel) + ":")]
                  ),
                  !_vm.updating
                    ? _c(
                        "span",
                        { staticClass: "kr-discount-panel-discount-value" },
                        [_vm._v(_vm._s(_vm.getAmountLabel(_vm.discount)))]
                      )
                    : _c(
                        "span",
                        { staticClass: "kr-discount-panel-discount-updating" },
                        [_vm._v(_vm._s(_vm.translate("discount_updating")))]
                      ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }