import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export const getRedirectState = () => {
  return {
    redirectDebug: false,
    redirectIframeOpen: false,
    redirectPopupOpen: false,
    redirectVersion: null,
    redirectionType: 'iframe', // or 'popup|tab'
    redirecting: false,
    redirectForm: null,
    receiptOpen: false,
    is3dsAbort: false,
    hasIframeCloseButton: false,
    redirectSize: {
      width: null,
      height: null
    },
    redirectTransaction: null,
    postPaymentRedirecting: false,
    orderStatus: null,
    iframeHeightDeduction: 0,
    show3DSWarning: false
  }
}

export const redirectActions = ($locator, app) => {
  return {
    redirection({ commit, dispatch, state, getters }, show = true) {
      const { iframeRedirection } = getters
      // Popup
      if (!iframeRedirection) {
        // remoteId
        dispatch('setRemoteId')
        commit('UPDATE', { redirectPopupOpen: show })
      } else {
        dispatch('calculateIframeHeightDeduction')
        // Iframe
        const $ghost = $locator.iframeController.ghostContainer
        // For popin form, adjust the iframe to fit inside the host popin (or for smartform popin)
        let { height, width } = getters.cappedRedirectSize
        const { isSmartForm, requireFullScreenRedirect } = getters

        if (isSmartForm && requireFullScreenRedirect) {
          // Compared with the red iframe size, the screen is small, so
          // let's force the small screen mode
          commit('UPDATE', { forcedSmallScreen: true })
        }

        // Set the iframe size
        if (show) {
          const styles = { height: `${height}px`, width: `${width}px` }
          $ghost.css(styles)
        }
        if (!isSmartForm) {
          const $parent = Zepto($ghost.parent())
          const $wrapper = $parent.parent()
          $parent[show ? 'addClass' : 'removeClass']('kr-secure-iframe-open')
          $wrapper[show ? 'addClass' : 'removeClass']('kr-secure-iframe-open')
        }

        // Iframe not embedded (separated modal)
        commit('UPDATE', { redirectIframeOpen: show })
        if ($ghost) $ghost[show ? 'show' : 'hide']()
      }
    },
    startRedirection({ commit, getters, state }, config) {
      const { paymentMethod, getMethodSize } = getters
      commit('UPDATE', {
        redirectVersion: config.is('billingRedirect') ? 'v1' : 'v2',
        redirecting: true,
        redirectForm: config.formId,
        is3dsAbort: false,
        hasIframeCloseButton:
          config.is('billingRedirect') && !config.hideCloseButton,
        redirectSize: {
          ...getMethodSize(paymentMethod, config)
        }
      })
    },
    finishRedirection({ commit, state, dispatch }, is3dsAbort = false) {
      if (!state.redirecting) return
      const data = { redirectSize: getRedirectState().redirectSize }
      if (state.redirecting) data.redirecting = false
      if (state.receiptOpen) data.receiptOpen = false
      data.is3dsAbort = is3dsAbort
      data.redirectForm = null
      data.redirectIframeOpen = false
      data.hasIframeCloseButton = false
      data.redirectPopupOpen = false
      const $modalViewContainer = document.querySelector(
        '.kr-smart-form-modal-views-container'
      )
      if ($modalViewContainer) $modalViewContainer.style.width = ''
      commit('UPDATE', data)
      dispatch('checkTransaction')
    },
    saveTransaction({ commit, state }, transaction) {
      commit('UPDATE', {
        orderStatus: transaction.clientAnswer.orderStatus.toLowerCase(),
        redirectTransaction: JSON.stringify(transaction)
      })
    },
    setReceiptOpen({ commit }, receiptOpen) {
      commit('UPDATE', { receiptOpen })
    },
    checkTransaction({ dispatch, state, getters }) {
      // If transaction is already saved, finish payment
      const { $bus, storeFactory, proxy, ghostQueue } = $locator
      const { isDeadEndPaymentMethod, paymentMethod } = getters
      const { formToken, publicKey, remoteId, dna } = state

      if (state.redirectTransaction) {
        const answer = JSON.parse(state.redirectTransaction)
        if (state.orderStatus === 'unpaid') {
          dispatch('error', { metadata: { answer } })
        } else {
          const paymentStore = storeFactory.create('payment', answer)
          dispatch('paymentProcessed')
          if (app === 'host')
            $bus.$emit(Events.krypton.message.payment, paymentStore)
          else proxy.send(paymentStore)
        }
        // Else, get transaction
      } else if (isDeadEndPaymentMethod) {
        const transactionStore = storeFactory.create('getTransaction', {
          paymentMethod,
          formToken,
          publicKey,
          remoteId,
          dna
        })
        if (app === 'ghost')
          $bus.$emit(Events.krypton.message.getTransaction, transactionStore)
        else {
          ghostQueue.send(transactionStore)
        }
      }
    },
    calculateIframeHeightDeduction({ commit, state, getters }) {
      const { isSmartForm } = getters

      let backButtonHeight = 0
      if (isSmartForm) {
        // Back button size
        const $backButton = document.querySelector(
          '.kr-method-content .kr-back-button'
        )
        if ($backButton) {
          // Clone the parent to get the full height including margin
          const $btnParent = $backButton.parentElement
          const $fakeBackButton = $btnParent.cloneNode(true)
          // Clean the clone content
          for (const child of $fakeBackButton.children) {
            if (!child.classList.contains('kr-back-button')) child.remove()
          }
          $fakeBackButton.style.opacity = '0'
          $fakeBackButton.style.position = 'absolute'
          $fakeBackButton.classList.add('kr-secure-iframe-visible')
          $fakeBackButton.classList.remove('kr-method-content--invisible')
          $btnParent.parentElement.appendChild($fakeBackButton)
          backButtonHeight = $fakeBackButton.offsetHeight ?? 0
          $btnParent.parentElement.removeChild($fakeBackButton)
        }
      }

      // If popin is not open yet, the calculation cannot be made by
      // measuring DOM element since they are not displayed yet
      if (!state.redirectIframeOpen) {
        const headerConf = state.form?.redirection?.header
        const propsToSum = [
          'height',
          'paddingTop',
          'paddingBottom',
          'borderTop',
          'borderBottom'
        ]
        let headerHeight = 0
        let modalBorder = 0
        for (const prop of propsToSum) {
          headerHeight += parseInt(headerConf?.[prop] ?? 0)
        }

        if (isSmartForm) {
          const modalConf = state.form.smartform.modal
          for (const prop of propsToSum) {
            modalBorder += parseInt(modalConf?.[prop] ?? 0)
          }
        }
        commit('UPDATE', {
          iframeHeightDeduction: headerHeight + backButtonHeight + modalBorder
        })
        return
      }

      // For fullscreen mode (popin form) calculate the size difference
      const $header = document.querySelector('.kr-popin-modal-header')
      let headerHeight = 0
      if ($header) {
        // Generate a fake header to get the height
        const $fakeHeader = $header.cloneNode(true)
        $fakeHeader.style.opacity = '0'
        $fakeHeader.classList.add('kr-secure-iframe-visible')
        $header.parentElement.appendChild($fakeHeader)
        headerHeight = $fakeHeader.offsetHeight ?? 0
        $header.parentElement.removeChild($fakeHeader)
      }

      // Modal border
      let modalBorder = 0
      const $modal = document.querySelector('.kr-smart-form-modal')
      if ($modal) {
        const $fakeModal = $modal.cloneNode(false)
        $fakeModal.style.opacity = '0'
        $fakeModal.classList.add('kr-secure-iframe-visible')
        $modal.parentElement.appendChild($fakeModal)
        modalBorder = parseInt(
          window.getComputedStyle($fakeModal).borderTopWidth
        )
        $modal.parentElement.removeChild($fakeModal)
      }

      commit('UPDATE', {
        iframeHeightDeduction: headerHeight + backButtonHeight + modalBorder
      })
    }
  }
}
export const redirectMutations = {}
export const redirectGetters = {
  iframeRedirection: state => state.redirectionType === 'iframe',
  /**
   * To help set the right size to the popin, returns true if screen is not big
   * enough to contain it with the default size.
   *
   * @param {Object} state
   * @param {Object} getters
   * @returns {boolean}
   */
  requireFullScreenRedirect: (
    { hostViewport, redirectSize },
    { isSmartForm }
  ) => {
    if (!isSmartForm) return false

    const { width } = redirectSize
    return width > hostViewport.width
  },
  cappedRedirectSize: (state, getters) => {
    const rs = state.redirectSize
    const fsr = getters.fullScreenRedirectSize
    const isFullWidth = rs.width >= fsr.width

    return {
      width: Math.min(rs.width, fsr.width),
      // If the popin is full width, the height is also full height
      height: isFullWidth ? fsr.height : Math.min(rs.height, fsr.height)
    }
  },

  /**
   * Return the popin size to be set if screen is too small and required
   * a full screen display.
   *
   * @param {Object} state
   * @param {Object} getters
   * @returns {Object.<string, number>}
   */
  fullScreenRedirectSize: ({ iframeHeightDeduction, hostViewport }) => {
    const { height, width } = hostViewport

    return {
      height: height - iframeHeightDeduction,
      width
    }
  },
  getMethodSize:
    state =>
    (method, redirConf = null) => {
      const smartForm = state?.dna?.smartForm
      const _methodSize = state._internals.smartForm.methodSize
      // Cards only mode (embedded form)
      if (!smartForm)
        return {
          width: redirConf?.width || 390,
          height: redirConf?.height || 434
        }
      const { width, height } =
        paymentMethodsConf.size[method] || smartForm[method] || {}
      return {
        width: _methodSize.width || width || redirConf?.width || 390,
        height: _methodSize.height || height || redirConf?.height || 434
      }
    },
  getPaymentMethodCloseButtonType: (state, getters) => {
    /**
     * KJS-2606 - Other value than 'POPUP' hides the back to shop button
     * Need to be discussed first
     */
    return 'POPUP'
    // return 'POPUP' - 'EMPTY' (TODO - 'REDIRECT')
    // switch (state.redirectionType) {
    //   case 'tab':
    //     return 'POPUP'
    //   case 'popup':
    //     if (getters.isMobile || getters.isTablet) return 'POPUP'
    //     else return null
    //   case 'iframe':
    //     // iframe always has closing "x" available
    //     return null
    // }
  }
}
