<template lang="pug">
  .kr-smart-form-wrapper(v-show="hasValidToken || isSmartFormPopin", :class="dynamicClasses.wrapper")
    PaymentTokenSmartButton( v-if="isSmartFormForcedToken"
      :paymentMethodToken="smartFormPaymentToken"
    )
    SmartFormTabHandler(v-if="isSmartFormPopin", position="first", :setup="isSmartFormOpen")
    SmartFormModalButton(v-if="hasModalButton", v-on:open="openSmartFormPopinWrapper")
    SmartFormEmbeddedMethodsList(v-else-if="hasRootMethodsList")
      template(v-slot:cardForm="")
        slot(v-if="renderEmbeddedForm")
    SmartFormLoadingOverlay()
    SmartFormModal(:open="isSmartFormOpen", :view="currentView")
      template(v-slot:full="")
        SmartFormModalMethodsList(v-if="isSmartFormPopin", :mode="isGroupingActivated ? 'groups' : 'list'")
          template(v-slot:cardForm="")
            slot(v-if="!renderEmbeddedForm")
      template(v-slot:group="")
        SmartFormModalBackButton(v-if="isSmartFormPopin && isGroupActive")
        SmartFormModalMethodsList(v-if="isGroupingActivated && isGroupActive", mode="list")
          template(v-slot:cardForm="")
            slot(v-if="!renderEmbeddedForm")
      template(v-slot:content="")
        SmartFormModalMethodsList(mode="method", v-on:back="navigateBack")
          template(v-slot:cardForm="")
            slot(v-if="!renderEmbeddedForm")
      template(v-slot:extras="")
        ExtrasForm(v-if="isSmartFormPopin", :display-mode="'embedded'")
      template(v-slot:extra="")
        .kr-smart-form-methods
          .kr-methods.kr-method-active.kr-method-cards
            .kr-method-content.kr-disabled-back-button
              slot(name="extra")
    SimpleModal
    ExtrasForm(v-if="!isSmartFormPopin", :display-mode="'modal'")
    SmartFormTabHandler(v-if="isSmartFormPopin")
    SmartFormLoadingOverlay(:only-window-redirection="true")
    KryptonLayer(:mode="isSmartFormPopin ? 'box' : 'unified'")
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Events from '@/configuration/Events'
import SmartFormLoadingOverlay from '@/host/components/smartform/LoadingOverlay'
import SmartFormModalBackButton from '@/host/components/smartform/ModalBackButton'
import SmartFormModalButton from '@/host/components/smartform/ModalButton'
import SmartFormModal from '@/host/components/smartform/Modal'
import SmartFormModalMethodsList from '@/host/components/smartform/ModalMethodsList'
import SmartFormEmbeddedMethodsList from '@/host/components/smartform/EmbeddedMethodsList'
import SmartFormTabHandler from '@/host/components/smartform/TabHandler'
import SmartFormWallet from '@/host/components/smartform/wallet/Wallet'
import SimpleModal from '@/host/components/controls/SimpleModal'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import KryptonLayer from '@/host/components/controls/KryptonLayer'
import PaymentTokenSmartButton from '@/host/components/smartbutton/PaymentToken'

/** MIXINS */
import PaymentMethodGroup from '@/host/components/mixins/PaymentMethodGroup'

/**
 * KJS-2043
 * The standard syntax [template(v-slot:full)] fails with JEST and PUG compilation
 * It gets compiled as [<template v-slot:full="v-slot:full">]
 * (see PUG attributes mirroring: https://pugjs.org/language/attributes.html#boolean-attributes)
 */
export default {
  name: 'SmartForm',
  components: {
    SmartFormLoadingOverlay,
    SmartFormModalBackButton,
    SmartFormModalButton,
    SmartFormModal,
    SmartFormModalMethodsList,
    SmartFormEmbeddedMethodsList,
    SmartFormTabHandler,
    SmartFormWallet,
    SimpleModal,
    ExtrasForm,
    KryptonLayer,
    PaymentTokenSmartButton
  },
  mixins: [PaymentMethodGroup],
  data() {
    return {
      modalHistory: ['full'],
      modalView: 'full'
    }
  },
  computed: {
    ...mapState(['redirecting', 'redirectIframeOpen', 'parsingToken']),
    ...mapGetters([
      'translate',
      'isCardMethod',
      'isSmartFormPopin',
      'isSmartFormOpen',
      'isFormFilled',
      'isSinglePaymentButton',
      'isActiveMethod',
      'numOfPaymentMethods',
      'hasSeveralPaymentMethods',
      'hasPaymentMethods',
      'hasCardMethodOption',
      'hasCardInRoot',
      'hasAnyCardBrand',
      'hasValidToken',
      'isMethodAvailableInDna',
      'isGroupingActivated',
      'cardsNotIntegrated',
      'outsideCardsForm',
      'hasCardHeader',
      'hasSmartButton',
      'isGroupActive',
      'hasCardMethodAvailable',
      'getFormElement',
      'isWallet',
      'isSmartFormForcedToken',
      'smartFormPaymentToken'
    ]),
    ...mapState({
      errorCode: state => state.error.errorCode,
      paymentMethods: state => state.smartForm.availablePaymentMethods,
      activeMethod: state => state.smartForm.activeMethod,
      activeGroup: state => state.smartForm.activeGroup,
      views: state => state.smartForm.navigation.views,
      currentView: state => state.smartForm.navigation.currentView,
      history: state => state.smartForm.navigation.history,
      hidden: state => state.smartForm.hidden,
      forcedOverlay: state => state.form.smartform.overlay.forcedDisplayState,
      cardTokens: state => state.dna?.tokens?.cards || []
    }),
    dynamicClasses() {
      return {
        wrapper: {
          'kr-type-popin': this.isSmartFormPopin,
          'kr-type-embedded': !this.isSmartFormPopin,
          'kr-several-payment-methods': this.hasSeveralPaymentMethods
        }
      }
    },
    hasModalButton() {
      return (
        this.isSmartFormPopin &&
        this.hasPaymentMethods &&
        !this.hidden &&
        !this.isSmartFormForcedToken
      )
    },
    hasRootMethodsList() {
      return (
        (this.renderEmbeddedForm || this.hasSeveralPaymentMethods) &&
        !this.hidden &&
        !this.isSmartFormForcedToken
      )
    },
    renderEmbeddedForm() {
      return (
        !this.outsideCardsForm &&
        this.cardsNotIntegrated &&
        this.hasAnyCardBrand &&
        !this.isSmartFormForcedToken
      )
    }
  },
  watch: {
    errorCode(val) {
      if (val) {
        if (
          this.modalView == 'content' &&
          !this.isCardMethod(this.activeMethod)
        ) {
          this.goBack()
        }
      }
    },
    redirectIframeOpen(val) {
      Zepto(this.$el)
        .parent()
        [val ? 'addClass' : 'removeClass']('kr-secure-iframe-visible')

      // Handle popin closure upon 3DS termination with expanded card form
      if (
        !val &&
        !this.activeMethod &&
        !this.activeGroup &&
        this.isSmartFormOpen &&
        !this.isSmartFormPopin
      ) {
        this.closeSmartFormPopin()
      }
    },
    isFormFilled(val) {
      if (val) {
        this.onFormFilled()
      }
    },
    /**
     * Emit below event only if it is not singlePaymentButton mode since
     * the event has already been emitted when calling "selectMethod" action.
     *
     * @since KJS_2643
     */
    activeMethod(method) {
      if (!this.isSinglePaymentButton && method) {
        this.$bus.$emit(Events.krypton.smartform.paymentMethodSelected, method)
      }
    },
    cardsNotIntegrated() {
      this.$bus.$emit(Events.krypton.form.relocation, {})
      this.$nextTick(() => {
        this.$bus.$emit(Events.iframe.relocation, { slaves: true })
        this.getFormElement().style.removeProperty('display') // Show (in case it's hidden)
      })
    }
  },
  created() {
    this.setNavigableElements()
    this.$busOn(Events.krypton.message.navigateBack, () => {
      this.navigateBack()
    })
    this.startExpandedFormSubscriptions()
    if (this.isSinglePaymentButton && this.hasCardInRoot && !this.isWallet) {
      this.selectMethod('CARDS')
    }
    const opts = {
      preventOff: [Events.krypton.form.relocation]
    }
    this.$busOn(
      Events.krypton.message.payment,
      () => {
        if (!this.redirectIframeOpen) {
          this.paymentProcessed()
          this.closeMethod()
        }
      },
      opts
    )
  },
  methods: {
    ...mapActions([
      'paymentProcessed',
      'openSmartFormPopinWrapper',
      'closeSmartFormPopin',
      'closeMethod',
      'navigateBack',
      'setNavigableElements',
      'selectMethod'
    ]),
    /**
     * For a SmartForm with expanded card form and single payment button,
     * the following event must cause the card to be seleted:
     * - a focus on input
     * - a click on the checkbox or select field
     *
     * If the form loses focus and is empty, CARDS should be unselected.
     *
     * @since KJS-2643
     */
    startExpandedFormSubscriptions() {
      this.$busOn(Events.krypton.field.event, ({ type }) => {
        if (this.isSinglePaymentButton && this.cardsNotIntegrated) {
          switch (type) {
            case 'focusReport':
            case 'focus':
            case 'triggerFocusActions':
            case 'userClick':
              this.selectMethod('CARDS')
              break
          }
        }
      })
    },
    /**
     * In case the form is filled without any of the event mentionned above
     * (e.g: by selecting a testcard on the toolbar) we should detect the form
     * as been (even partially) filled and set CARDS a the selected method.
     *
     * @since KJS-2643
     */
    onFormFilled() {
      if (this.isSinglePaymentButton && this.cardsNotIntegrated) {
        this.selectMethod('CARDS')
      }
    }
  }
}
</script>
