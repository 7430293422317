import _ from 'underscore'
import Zepto from 'zepto-webpack'
import BaseDetector from '@/host/service/BaseDetector'
import ScriptCleaner from '@/host/service/ScriptCleaner'
import { hasRequireJS } from '@/host/service/vendor/Utils'
import endpointMap from '@/configuration/sources/EndpointMap.yml'

export default class StaticFinder {
  constructor($locator) {
    this.$locator = $locator
    this.endpointDetector = $locator.endpointDetector
  }

  detectAddresses(base = null, version = null) {
    const endpointDetector = this.endpointDetector
    const $store = this.$locator.$store
    let scriptsBase

    if (!base) scriptsBase = this.baseDetectorByScripts()
    if (!version) version = this.version
    if (!base)
      base =
        scriptsBase.indexOf('http') !== -1
          ? scriptsBase
          : this.baseDetectorByLocation(scriptsBase)
    if (!endpointDetector.isWhitelistedEndpoint(base))
      this.baseDetectorByFormToken()

    let url = this.jsDetectorLibrary()

    $store.dispatch('update', {
      merchantDomain: location.hostname,
      baseAddress: base,
      baseDomain: url.domain,
      applicationPath: url.path
    })
  }

  jsDetectorLibrary() {
    const scripts = Zepto('script')
    let returnValue

    for (let i = 0; i < scripts.length; i++) {
      let $scriptItem = scripts[i]
      let scriptSrc = ScriptCleaner.run($scriptItem.getAttribute('src'))

      if (_.isString(scriptSrc)) {
        if (/kr\-payment\-form\.min\.js/.test(scriptSrc)) {
          returnValue = this.$locator.url.explainUrl(scriptSrc)
        }
      }
    }

    return returnValue
  }

  baseDetectorByScripts(scripts = null, version = null, win = null) {
    const _this = this
    win = win || window

    // Compile the candidates
    let extraScripts = []
    if (!scripts) scripts = Zepto('script')
    if (!version) version = '%%target_version%%'

    if (win && hasRequireJS(win)) {
      // Add to the scripts the scripts loaded by RequireJS
      let requirePaths = win.require.s.contexts._.config.paths
      let requireKeys = Object.keys(requirePaths)
      _.each(requireKeys, reqKey => {
        extraScripts.push(requirePaths[reqKey])
      })
    }

    // Parse zepto object to array
    let scriptElements = []
    _.each(scripts, el => {
      scriptElements.push(el)
    })

    scripts = scriptElements
    scripts = _.union(scripts, extraScripts)

    // Iterate over the scripts
    for (let i = 0; i < scripts.length; i++) {
      let detectedScript = BaseDetector.read(scripts[i], version)
      if (detectedScript) {
        return detectedScript
      }
    }

    return ''
  }

  baseDetectorByFormToken() {
    const formToken = this.getFormTokenFromUrl()
    if (!formToken) return ''

    const endpoints = endpointMap.items
    const prefix = formToken.substring(0, 2)
    let endpoint = _.findWhere(endpoints, { prefix }) || ''

    if (endpoint) {
      endpoint = `${endpoint.endpoint.api}/static/js/krypton-client/V4.0`
    }

    return endpoint
  }

  getFormTokenFromUrl() {
    let formToken = ''
    const scripts = Zepto('script')

    // If already on the store, use it from there
    let tokenFromStore = this.$locator.$store.state.formToken
    if (tokenFromStore) formToken = tokenFromStore

    // Check if it's defined on script
    if (!formToken || !formToken.length) {
      for (let i = 0; i < scripts.length; i++) {
        let $script = scripts[i]
        let scriptSrc = $script.getAttribute('src')

        if (_.isString(scriptSrc)) {
          if (/kr\-payment\-form\.min\.js/.test(scriptSrc)) {
            let matches = /^.*form[(t|T)]{1}oken=([^\#]*)$/.exec(scriptSrc)
            if (matches && matches.length === 2) {
              formToken = matches[1].split('#')[0].split('&')[0] // cleaning token of any extra parameters and hash
            }
            break
          }
        }
      }

      // Check if it's defined on .kr-embedded
      if (!formToken && Zepto('.kr-embedded[kr-form-token]').length) {
        formToken = Zepto('.kr-embedded[kr-form-token]').attr('kr-form-token')
      }

      // Show a console warning if it's defined nowhere
      if (!formToken)
        console.warn(
          '[Krypton-Client %%library_version%%] The FormToken has been defined nowhere'
        )
    }

    return formToken
  }

  baseDetectorByLocation(relativeBase, win = null) {
    if (!win) win == window
    if (relativeBase !== '') {
      const host = win.location.origin
      const path = relativeBase.split(/..\//).join('')

      return `${host}/${path}`
    }

    if (typeof win !== 'undefined') {
      let domain = `${window.location.protocol}//${window.location.hostname}`
      return `${domain}/head`
    }
  }
}
