export const getEnvironmentState = () => {
  return {
    browser: { name: undefined, version: undefined },
    os: { name: undefined, version: undefined },
    platform: { type: undefined, model: undefined },
    forcedSmallScreen: false,
    isWebView: undefined
  }
}

export const environmentActions = {}
export const environmentMutations = {}
export const environmentGetters = {
  isMobile: state => {
    if (!state.platform) return null
    return state.platform.type === 'mobile'
  },
  isDesktop: state => {
    if (!state.platform) return null
    return state.platform.type === 'desktop'
  },
  isTablet: state => {
    if (!state.platform) return null
    return state.platform.type === 'tablet'
  },
  isIphone: state => {
    if (!state.platform) return null
    return state.platform.model === 'iphone' || state.platform.model === 'ipod'
  },
  isIpad: state => {
    if (!state.platform) return null
    return state.platform.model === 'ipad'
  },
  isAndroid: state => {
    if (!state.os) return null
    return state.os.name === 'Android'
  },
  isIos: state => {
    if (!state.os) return null
    return state.os.name === 'iOS'
  },
  isMacOs: state => {
    return state.os?.name === 'macOS'
  },
  isSafari: state => {
    return state.browser?.name === 'Safari'
  },
  isIE11: state => {
    return (
      state.browser?.name === 'Internet Explorer' &&
      state.browser?.version === '11.0'
    )
  },
  isOldBrowser: (state, getters) => {
    if (!state.browser) return null
    return (
      state.browser.name === 'Internet Explorer' ||
      (state.browser.name === 'Safari' && state.os.name === 'Windows') ||
      state.browser.name === 'Microsoft Edge' ||
      (getters.isAndroid && parseInt(state.os.version[0]) <= 5) ||
      (getters.isIos && parseInt(state.os.version[0]) <= 8)
    )
  },
  isSmallScreen: ({ forcedSmallScreen }) => {
    return (
      screen.availHeight <= 450 || screen.availWidth <= 415 || forcedSmallScreen
    )
  },
  isUsingCDN: state => {
    return /^https:\/\/static/.test(state.baseAddress)
  },
  majorOsVersion: state => state.os?.version?.split('.')[0]
}
