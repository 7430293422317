var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExtrasFormVisible
    ? _c(
        "div",
        { staticClass: "kr-extras-form" },
        [
          _vm.displayMode === "modal"
            ? _c(
                "ExtrasFormModal",
                [
                  _c("span", { staticClass: "kr-extras-form-header" }, [
                    _vm._v(
                      _vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.header)))
                    ),
                  ]),
                  _c("ExtrasFormContent"),
                ],
                1
              )
            : _vm._e(),
          _vm.displayMode === "embedded"
            ? [
                _vm.isSmartFormPopin
                  ? _c("SmartFormModalBackButton")
                  : _vm._e(),
                _c("span", { staticClass: "kr-extras-form-header" }, [
                  _vm._v(
                    _vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.header)))
                  ),
                ]),
                _c("ExtrasFormContent"),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }