import {
  getFieldElement,
  getFormElement,
  getSmartButtonElements,
  getSmartButtonWrapperElements,
  getSmartFormElement,
  hasSmartElements,
  isFormRendered
} from '../../common/util/dom'

export const getDomState = () => {
  return {
    dom: {
      onlyTaggedElements: false
    }
  }
}

export const domActions = $locator => {
  return {
    setRenderElements({ dispatch, commit }, $elements) {
      if (!Array.isArray($elements)) {
        $elements = [$elements]
      }
      const { errors, $validElements } =
        $locator.domValidator.validateElements($elements)

      if ($validElements.length > 0) {
        $locator.domValidator.addKRElement($validElements)
        dispatch('update', { dom: { onlyTaggedElements: true } })
      }

      if (errors.length > 0) {
        dispatch('error', {
          errorCode: 'CLIENT_715',
          children: errors.map(error => ({ errorCode: error })),
          metadata: {
            console: true
          }
        })
      }
    }
  }
}
export const domMutations = {}
export const domGetters = {
  getFormElement: getMethod(getFormElement),
  getSmartFormElement: getMethod(getSmartFormElement),
  getSmartButtonElements: getMethod(getSmartButtonElements),
  getSmartButtonWrapperElements: getMethod(getSmartButtonWrapperElements),
  hasSmartElements: getMethod(hasSmartElements),
  getFieldElement:
    ({ dom: { onlyTaggedElements } }) =>
    fieldName =>
      getFieldElement(fieldName, onlyTaggedElements),
  isFormRendered:
    ({ dom: { onlyTaggedElements } }) =>
    () =>
      isFormRendered(onlyTaggedElements)
}

function getMethod(fn) {
  return ({ dom: { onlyTaggedElements } }) => {
    return ($el = document) => fn($el, onlyTaggedElements)
  }
}
