<template lang="pug">
  button.kr-back-button(
    type="button",
    @keyup.9="tabReport",
    @keydown.9="tabReport",
    @focus="focusReport",
    @click="goBack()",
    @keyup.enter="goBack()",
    :class="{'kr-focus': hasFocus}"
  )
    .kr-arrow-left(v-html='leftArrow')
    label {{ renderedLabel }}
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'

export default {
  name: 'SmartFormModalBackButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      leftArrow: PreloadedAssets.arrows.left,
      hasFocus: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isCardMethod',
      'isCardMethodActive',
      'paymentMethod',
      'isGroupActive',
      'hasDeadEndPaymentMethod',
      'isExtrasFormVisible'
    ]),
    ...mapState({
      currentFocus: state => state.navigation.currentFocus
    }),
    renderedLabel() {
      return this.label || this.translate('smartform_popin_return')
    }
  },
  watch: {
    currentFocus(currentFocus) {
      if (!this.visible) return
      const isFocused = currentFocus === `back`
      if (isFocused) this.$el.focus()
      this.hasFocus = isFocused
    }
  },
  methods: {
    ...mapActions([
      'focusNext',
      'focusPrevious',
      'focus',
      'closeMethod',
      'error',
      'navigateBack',
      'hideExtrasForm',
      'cleanError'
    ]),
    tabReport(e) {
      e.preventDefault()
      if (e.type === 'keydown') {
        this[e.shiftKey ? 'focusPrevious' : 'focusNext']()
      }
    },
    focusReport(e) {
      if (this.currentFocus !== 'back') this.focus('back')
    },
    goBack() {
      if (this.disabled) return
      if (
        !this.isCardMethod(this.paymentMethod) &&
        this.paymentMethod &&
        !this.hasDeadEndPaymentMethod()
      ) {
        this.navigateBack()
        this.error('CLIENT_101')
        if (this.isExtrasFormVisible) {
          this.closeMethod()
          // need the same timeout as the extras modal component to have the return animation
          setTimeout(() => {
            this.hideExtrasForm()
          }, 100)
        }
      } else if (!this.paymentMethod && this.isGroupActive) {
        this.cleanError()
        this.navigateBack()
      } else if (this.paymentMethod) {
        this.cleanError()
        this.closeMethod()
      }
    }
  }
}
</script>
