<template lang="pug">
  .kr-popin-redirection(v-show="redirectIframeOpen && !isSmartForm")
    .kr-background
    .kr-popin-redirection-modal(:class="dynamicClasses.modal")
      KryptonPopinHeader
      KryptonGhostContainer
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonGhostContainer from '@/host/components/KryptonGhostContainer'

export default {
  name: 'KryptonPopinRedirection',
  components: {
    KryptonPopinHeader,
    KryptonGhostContainer
  },
  computed: {
    ...mapGetters(['isSmallScreen', 'isSmartForm']),
    ...mapState(['redirectIframeOpen']),
    dynamicClasses() {
      return {
        modal: {
          'kr-popin-redirection-modal--mobile': this.isSmallScreen
        }
      }
    }
  }
}
</script>
