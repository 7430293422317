import _ from 'underscore'
import Internals from '@/configuration/sources/Internals.yml'

export const getInternalsState = () => {
  return {
    _internals: {
      devMode: false,
      devDomainPrefix: '',
      serverRequestTimeout: 45000,
      integrationCase: '', // Suspicious security detection
      smartForm: {
        methodSize: {
          height: null,
          width: null
        }
      },
      doRegister: {
        forced: false
      },
      delayedPaymentDoneRed: false
    }
  }
}

export const internalsActions = {}
export const internalsMutations = {}
export const internalsGetters = {
  forcedDomain: ({ libraryVersion, _internals: { devDomainPrefix } }) => {
    // Check if the domain is in the list
    const allowedDomain = Internals.devDomains.some(rx => {
      if (typeof rx === 'string') rx = new RegExp(rx)
      return rx.test(location.href)
    })
    // FIXME: libraryVersion is undefined in the store when it should be defined (GHOST)
    const libVersion = libraryVersion || '%%library_version%%'
    if (
      allowedDomain &&
      /^V4\.\d.+dev$/.test(libVersion) &&
      devDomainPrefix &&
      /^[a-zA-Z0-9_.-]*\.lbg\.office\.fr\.lyra(:[0-9]{1,5})?$/.test(
        devDomainPrefix
      )
    )
      return `https://${devDomainPrefix}/api-payment`
    else return null
  },
  /**
   * Suspicious security detection
   */
  integrationCase: ({ _internals: { integrationCase } }) => integrationCase
}
