<template lang="pug">
button.kr-payment-button.kr-smart-form-single-payment-button(
  type="button"
  v-if="renderSinglePaymentButton"
  v-show="isVisible"
  :class="dynamicClasses"
  :style="styles"
  :disabled="isDisabled"
  v-html="payBtnHTML"
  @click="pay"
  @keyup.9="tabReport"
  @keydown.9="tabReport"
  tabindex=1
)
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { PaymentButtonMixin } from '@/host/components/mixins/PaymentButton'

export default {
  name: 'SmartFormSinglePaymentButton',
  mixins: [PaymentButtonMixin],
  computed: {
    ...mapGetters([
      'isSmartFormOpen',
      'isApplePayOpen',
      'isSinglePaymentButton',
      'hasPaymentMethods',
      'hasCardMethodAvailable',
      'hasSmartButton',
      'hasValidToken'
    ]),
    ...mapState([
      'redirectIframeOpen',
      'redirectPopupOpen',
      'processingPayment'
    ]),
    ...mapState({
      paymentDone: state => state.smartForm.paymentDone,
      activeMethod: state => state.smartForm.activeMethod,
      singlePaymentButton: state => state.form.smartform.singlePaymentButton
    }),
    isVisible() {
      return this.singlePaymentButton.visibility === true
    },
    isLoading() {
      return !!(
        this.redirectIframeOpen ||
        this.redirectPopupOpen ||
        this.isSmartFormOpen ||
        this.isApplePayOpen ||
        this.activeMethod ||
        this.processingPayment ||
        this.paymentDone
      )
    },
    isDisabled() {
      return this.isLoading
    },
    dynamicClasses() {
      return {
        'kr-discount-update': this.discountUpdate,
        'kr-loading': this.isLoading
      }
    },
    renderSinglePaymentButton() {
      return (
        this.hasValidToken &&
        this.isSinglePaymentButton &&
        (this.hasPaymentMethods ||
          (this.hasCardMethodAvailable && !this.hasSmartButton('CARDS')))
      )
    }
  },
  methods: {
    ...mapActions(['openSelectedMethod']),
    pay() {
      this.openSelectedMethod()
    }
  }
}
</script>
