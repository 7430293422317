import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import { MethodDescriptor } from '../../../common/model/PaymentMethod'

/**
 * This class handles the reception of the Delete token store action
 * from any of the iframes and handles validation on the ghost
 * and executing the workflow
 */
export default class DeleteTokenTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'deleteToken')
    this.browserRequests = $locator.browserRequests
  }

  /**
   * Delete token action
   *
   * @method onEvent
   * @param {Delete_Token_Action} deleteTokenAction Delete token store action
   */
  async onEvent({ formId, paymentMethodToken, method }) {
    this.formId = formId
    try {
      await super.onEvent({ paymentMethodToken })
      this.paymentMethod = new MethodDescriptor(
        method,
        paymentMethodToken
      ).toString()
      const { response } = await this.callTokenCancel(paymentMethodToken)
      if (response.status === 'ERROR') {
        this.onError(response.answer)
      } else {
        this.$store.dispatch('closeModal', {
          postCloseAction: `cardForm_${formId}/deleteDNAToken`,
          postCloseActionProps: paymentMethodToken
        })
      }
    } catch (error) {
      this.onError(error)
    }
  }

  async onError(error, path = 'ghost/service/DeleteTokenTrigger.onEvent') {
    this.$store.dispatch('closeModal')
    super.onError(error, path)
  }

  /**
   * Calls the ProcessPayment
   */
  async callTokenCancel(paymentMethodToken) {
    const requestData = this.storeFactory.create('requestData', {
      url: this.restAPI.getTokenCancelUrl(this.endpoint),
      objectData: {
        formToken: this.$store.state.formToken,
        publicKey: this.$store.state.publicKey,
        paymentMethodToken,
        wsUser: this.restAPI.getWSUser()
      },
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }
}
