var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.redirectIframeOpen && !_vm.isSmartForm,
          expression: "redirectIframeOpen && !isSmartForm",
        },
      ],
      staticClass: "kr-popin-redirection",
    },
    [
      _c("div", { staticClass: "kr-background" }),
      _c(
        "div",
        {
          staticClass: "kr-popin-redirection-modal",
          class: _vm.dynamicClasses.modal,
        },
        [_c("KryptonPopinHeader"), _c("KryptonGhostContainer")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }