import Zepto from 'zepto-webpack'
import { uniqueId } from 'underscore'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import SmartForm from '@/host/components/smartform/SmartForm'
import SmartButton from '@/host/components/smartbutton/SmartButton'
import SmartButtonError from '@/host/components/smartbutton/Error'
import KryptonFormError from '@/host/components/controls/KryptonFormError'
import Skeleton from '@/host/components/smartform/Skeleton'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import SmartFormSinglePaymentButton from '@/host/components/smartform/SinglePaymentButton'

import { GlobalThemeMixin } from '@/host/components/mixins/GlobalTheme'

export default locator => {
  return ({ element: $el }) => {
    const identifier = uniqueId('krvue-')
    $el.setAttribute('krvue', identifier)

    return appLoader(locator, Zepto($el), {
      el: `[krvue="${identifier}"]`,
      provide: {
        context: {}
      },
      components: {
        SmartForm,
        SmartButton,
        SmartButtonError,
        KryptonFormError,
        Skeleton,
        ExtrasForm,
        SmartFormSinglePaymentButton
      },
      mixins: [GlobalThemeMixin],
      mounted() {
        this.$el.setAttribute('kr-resource', '')
      }
    })
  }
}
