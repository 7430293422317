import _ from 'underscore'

export const getNavigationState = () => {
  return {
    navigation: {
      currentFocus: '',
      available: [],
      closed: false
    }
  }
}

export const navigationActions = {
  setNavigableElements({ commit, state, getters }) {
    const { smartForm } = state
    const { activeMethod, availablePaymentMethods } = smartForm

    let available = []

    if (!activeMethod) {
      available = availablePaymentMethods
    } else if (getters.isCardMethod() && getters.isSmartFormPopin) {
      available = ['back']
    }

    commit('UPDATE', { navigation: { available } })
  },
  focusNext({ commit, state, getters, dispatch }) {
    const { currentFocus, available } = state.navigation
    const { isSmartFormOpen } = getters
    // If the popin is open, move the focus to the content
    if (
      !available.length ||
      (currentFocus === 'back' &&
        available.length === 1 &&
        available[0] === 'back')
    ) {
      if (!isSmartFormOpen) return
      else {
        const $tabHandler = document.querySelector(
          '.krypton-tab-handler input.krypton-focus-handler'
        )
        $tabHandler.focus()
        dispatch('resetFocus')
        return
      }
    }
    let toFocus = `${available[0]}`
    if (currentFocus) {
      const current = currentFocus
      for (const i in available) {
        if (available[i] === current) {
          if (i < available.length - 1)
            toFocus = `${available[parseInt(i) + 1]}`
          break
        }
      }
    }
    commit('UPDATE', {
      navigation: { currentFocus: toFocus }
    })
  },
  focusPrevious({ commit, state }) {
    const { currentFocus, available } = state.navigation
    if (!available.length) return
    let toFocus = `${available[available.length - 1]}`
    if (currentFocus) {
      const current = currentFocus
      for (const i in available) {
        if (available[i] === current) {
          if (parseInt(i)) toFocus = `${available[parseInt(i) - 1]}`
          break
        }
      }
    }
    commit('UPDATE', {
      navigation: { currentFocus: toFocus }
    })
  },
  focus({ commit, state }, element) {
    if (!~state.navigation.available.indexOf(element)) return
    commit('UPDATE', {
      navigation: { currentFocus: element }
    })
  },
  resetFocus({ commit }) {
    commit('UPDATE', { navigation: { currentFocus: '' } })
  }
}
export const navigationMutations = {}
export const navigationGetters = {}
