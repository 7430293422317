var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "krypton-tab-handler" }, [
    _c("input", {
      staticClass: "kr-smart-form-focus-handler",
      staticStyle: { position: "fixed", left: "-9999px", top: "-9999px" },
      attrs: { type: "text" },
      on: { focus: _vm.onFocus },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }