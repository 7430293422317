import { isNull, isEmpty } from 'underscore'
import { castValue } from '@/common/util/values'
import { getScriptElement } from '@/common/util/dom'
import { setValueToPath, getPathValue } from '@/common/util/object'
import {
  setupConfiguration,
  setupConfigurationEvents
} from '@/host/service/ConfigurationUtils'
import CommonAttrs from '@/configuration/sources/domAttributes/Common.yml'
import FormAttrs from '@/configuration/sources/domAttributes/Form.yml'
import SmartFormAttrs from '@/configuration/sources/domAttributes/SmartForm.yml'
import AttrBlacklist from '@/configuration/sources/domAttributes/Blacklist.yml'
import Events from '@/configuration/Events'
import defaultState from '@/store/state'
import { getCardFormState } from '@/store/modules/namespace/cardForm.js'

export default class DomReader {
  constructor($locator) {
    this.$locator = $locator
    this.parsed = false
    this.hasDomElements = false

    this.inFieldAttrs = ['placeholder', 'label', 'contentLabel']
    this.formProps = this.inFieldAttrs.map(attr => `${attr}s`)
    this.cardFormProps = Object.keys(getCardFormState())
    this.resetListener()
  }

  /**
   * Listen to reset event
   */
  resetListener() {
    const _this = this
    this.$locator.$bus.$on(Events.krypton.destroy, message => {
      _this.parsed = false
    })
  }

  /**
   * Parse the dom, collect KR attributes and saves them into the store
   * if they have not been set yet
   */
  collect(spaInit) {
    const { $bus, $store, themeHandler } = this.$locator
    const { getFormElement, hasSmartElements } = $store.getters
    const domAttrs = [...CommonAttrs, ...FormAttrs, ...SmartFormAttrs]
    let conf = {}
    let cardFormConf = {}

    return new Promise((resolve, reject) => {
      // Dom already parsed
      if (!this.parsed) {
        // Check the accepted attributes conf
        for (const attrConf of domAttrs) {
          let value = this.getAttribute(attrConf)
          // if there is value defined, cast it
          if (!isNull(value) || this.canBeNull(attrConf.casting)) {
            value = castValue(
              this.$locator,
              value,
              attrConf.attribute,
              attrConf.casting
            )

            // Check if there is a value already setted in the store (higher preference)
            if (!this.hasStoreValue(attrConf.name) && value !== '') {
              // Immediately set value in store if flag is set
              if (attrConf.immediate === true) {
                const update = setValueToPath({}, attrConf.name, value)
                if (
                  !!~this.cardFormProps.indexOf(attrConf.name.split('.')[0])
                ) {
                  $store.dispatch('addFormPreset', {
                    action: 'update',
                    params: update
                  })
                } else {
                  $store.dispatch('update', update)
                }
                continue
              }
              if (!!~this.cardFormProps.indexOf(attrConf.name.split('.')[0])) {
                cardFormConf = setValueToPath(
                  cardFormConf,
                  attrConf.name,
                  value
                )
                continue
              }
              // setup the update conf object
              conf = setValueToPath(conf, attrConf.name, value)
              // Props with values in form and root
              if (~this.formProps.indexOf(attrConf.name.split('.')[0])) {
                conf = setValueToPath(conf, `form.${attrConf.name}`, value)
              }
            }
          }
        }

        // SmartForm
        conf.formWidget = hasSmartElements() ? 'smartForm' : 'default'

        // Update store
        if (!isEmpty(cardFormConf)) {
          $store.dispatch('addFormPreset', {
            action: 'update',
            params: cardFormConf
          })
        }
        $store.dispatch('update', conf)
        // Read KR_CONFIGURATION
        setupConfiguration({ $store, themeHandler })
        setupConfigurationEvents($bus, $store.state.events)
        // Check
        this.postCollectionCheck(conf)
      }

      // Add the proper modifications to the dom
      this.adaptDom($store)

      if (getFormElement() || hasSmartElements()) {
        this.parsed = true
        this.hasDomElements = true
      }
      // SPA initial load, should reparse again later on as there might be new elements
      if (conf.spaMode && !spaInit) this.parsed = false
      resolve($store.state)
    })
  }

  /**
   * Looks for the attribute in the dom (script, in-field, form)
   *
   * @see KJS-2383 | Perform DOM queries only when necessary to
   *      avoid unnecessary calls.
   */
  getAttribute(conf) {
    // SmartForm attribute type? - goes first
    const {
      getFormElement,
      getSmartFormElement,
      getSmartButtonElements,
      getFieldElement,
      onlySmartButtons
    } = this.$locator.$store.getters

    const $smartForm = getSmartFormElement()

    if (this.isSmartFormAttribute(conf.name)) {
      if ($smartForm) return $smartForm.getAttribute(conf.attribute)
    }

    // Script - top preference
    const $script = getScriptElement()
    if ($script) {
      // ignore attribute if there are only smartbuttons
      if (
        AttrBlacklist.onlySmartButtons.includes(conf.attribute) &&
        onlySmartButtons
      )
        return

      const scriptAttr = $script.getAttribute(conf.attribute)

      if (scriptAttr || scriptAttr === '') return scriptAttr
    }

    // In-field attribute - second choice
    if (~this.inFieldAttrs.indexOf(conf.casting)) {
      // get field name from attribute name
      const field = this.getFieldName(conf)
      // get field dom element
      const $field = getFieldElement(field)

      if ($field && $field.getAttribute(conf.attribute)) {
        return $field.getAttribute(conf.attribute)
      }
    }

    // SmartForm attribute - third choice
    if ($smartForm) {
      const sfAttr = $smartForm.getAttribute(conf.attribute)
      if (sfAttr) return sfAttr
    }

    // SmartButton attribute - fourth choice
    const $smartButtons = getSmartButtonElements()

    if ($smartButtons && $smartButtons.length) {
      for (const idx in $smartButtons) {
        const sbAttr = $smartButtons[idx].getAttribute(conf.attribute)
        if (sbAttr) return sbAttr
      }
    }

    // Form - last choice
    const $form = getFormElement()

    if ($form) return $form.getAttribute(conf.attribute)

    return null
  }

  /**
   * Return the parsed field name from the given prop configuration object
   */
  getFieldName(conf) {
    let field = conf.name.replace(`${conf.casting}s.`, '')
    field = `${field.charAt(0).toLowerCase()}${field.substring(1)}`
    for (const fieldName of ['installmentNumber', 'firstInstallmentDelay']) {
      if (!field.indexOf(fieldName)) return fieldName
    }
    return field
  }

  /**
   * Checks if the given property has a value in the store (higher preference) to
   * know if it should be saved
   */
  hasStoreValue(path) {
    const state = this.$locator.$store.state
    const defState = defaultState()
    const propArr = path.split('.')
    const lastProp = propArr.pop()
    const parentPath = propArr.join('.')

    // Simple properties (root)
    if (!parentPath) {
      return !!(defState.hasOwnProperty(path) && state[path] !== defState[path])
      // Props with values in form and root
    } else if (~this.formProps.indexOf(path.split('.')[0])) {
      return !!(
        (getPathValue(defState, parentPath).hasOwnProperty(lastProp) &&
          getPathValue(state, path) !== getPathValue(defState, path)) ||
        (getPathValue(defState, `form.${parentPath}`).hasOwnProperty(
          lastProp
        ) &&
          getPathValue(state, `form.${path}`) !==
            getPathValue(defState, `form.${path}`))
      )
    }

    // Props with path
    const statePathValue = getPathValue(state, path)
    const defStatePathValue = getPathValue(defState, path)

    let areEqual
    if (Array.isArray(statePathValue) && Array.isArray(defStatePathValue)) {
      areEqual = statePathValue.every((el, i) => el === defStatePathValue[i])
    } else {
      areEqual = statePathValue === defStatePathValue
    }

    return !!(
      getPathValue(defState, parentPath).hasOwnProperty(lastProp) && !areEqual
    )
  }

  postCollectionCheck(conf) {
    const { publicKey } = this.$locator.$store.state
    const { translate, onErrorTranslationLoaded } = this.$locator.$store.getters
    if (!conf.publicKey && !publicKey) {
      onErrorTranslationLoaded(() => {
        const code = 'CLIENT_501'
        const message = translate(code)

        if (window.console) {
          window.console.warn('[Error] Krypton Client', code, message)
        }
      })
    }
  }

  /**
   * Makes the proper adaptations to the dom from the collected configuration
   *
   * @see KJS-2030  Add role=form for accessibility
   */
  adaptDom({ state, getters, dispatch }) {
    // SmartForm automatic configuration
    const { getFormElement, getSmartFormElement } = this.$locator.$store.getters
    const $smartForm = getSmartFormElement()
    if ($smartForm) {
      // smartform, always form embedded mode
      const updateObj = { formType: 'embedded' }

      // Embedded form outside of smart form
      if (getFormElement() && !getFormElement($smartForm)) {
        // Keep the form type
        delete updateObj.formType
      }

      dispatch('update', updateObj)
    }

    const $form = getFormElement()
    if (!$form) return
    if (state.formType) {
      $form.setAttribute('kr-type', state.formType)
      if (getters.isFormPopin) $form.setAttribute('kr-popin', '')
      else $form.removeAttribute('kr-popin')
    }

    $form.setAttribute('kr-parsed', '1')
    $form.setAttribute('role', 'form')
  }

  /**
   * Checks if the attribute is specific for smart form
   */
  isSmartFormAttribute(attr) {
    return !attr.indexOf('smartForm')
  }

  canBeNull(casting) {
    return (
      casting === 'hasAttribute' ||
      casting === 'hasNoAttribute' ||
      casting === 'popin'
    )
  }
}
