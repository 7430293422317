<template lang="pug">
.kr-smart-button-wrapper
  SmartButtonSkeleton(v-if ="(!isSyncReady && isVisible) || !hasValidToken")
  .kr-smart-button.kr-sm-internal(
    v-if="isSyncReady && isVisible"
    :class="dynamicClasses", 
    @click="openCard"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :title="title"
    :kr-payment-method="cleanPaymentMethod.toLowerCase()"
    :style="styles.button"
      )
    // Regular label
    .kr-method-info(v-if="(!spinnerVisible || !isLoading)")
      .kr-method-icon(v-if="icon", v-html="getCustomPaymentMethodIcon(method) || icon")
      .kr-label
        label.kr-method-label.forced-payment-token {{methodLabel}}
        label.kr-amount-label {{amountLabel}}
      span.kr-method-badge(v-if="applePaySimulator") Simulator
      span.kr-method-badge(v-else-if="isPayPalSandbox") Sandbox
    .kr-method-info(v-if="spinnerVisible && isLoading", :class="{'kr-hidden': spinnerVisible && isLoading}")
    KryptonLoadingAnimation(v-if="spinnerVisible && isLoading")
  krypton-layer(mode="unified" :method="method")
  SmartButtonError(:reference="reference")
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Events from '@/configuration/Events'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'

import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import SmartButtonSkeleton from '@/host/components/smartbutton/Skeleton'
import { ApplePayMixin } from '@/host/components/mixins/ApplePay'
import KryptonLayer from '@/host/components/controls/KryptonLayer'
import SmartButtonError from '@/host/components/smartbutton/Error'
import Modal from '@/configuration/sources/SimpleModal.yml'

/**
 * Component used for smartForm PaymentMethodToken + forced
 * Cards payment method not supported
 *
 * @since KJS-3308
 */
export default {
  name: 'PaymentTokenSmartButton',
  components: {
    KryptonLoadingAnimation,
    SmartButtonSkeleton,
    SmartButtonError,
    KryptonLayer
  },
  mixins: [ApplePayMixin],
  props: {
    paymentMethodToken: { type: Object, required: true }
  },
  data() {
    return {
      hover: false,
      icon: null,
      isSyncReady: false
    }
  },
  computed: {
    ...mapState(['amountLabel', 'isUnitaryTest', 'language', 'disabledForm']),
    ...mapGetters([
      'translate',
      'isMethodAvailable',
      'isMethodAvailableInDna',
      'getCustomPaymentMethodLabel',
      'getCustomPaymentMethodIcon',
      'isApplePaySimulator',
      'hasValidToken'
    ]),
    ...mapState({
      errorCode: state => state.error.errorCode,
      activeMethod: state => state.smartForm.activeMethod,
      activeMethodMetadata: state => state.smartForm.activeMethodMetadata,
      buttonConfig: state => state.form.smartButton.button,
      spinnerVisible: state => state.form.smartButton.button.spinnerVisible,
      paymentMethods: state => state.smartForm.availablePaymentMethods
    }),
    styles() {
      const styles = {}
      styles.button = {}
      if (this.hover) {
        styles.button.border = this.buttonConfig.$hover.border
      }
      return styles
    },
    dynamicClasses() {
      return {
        'kr-hover': this.hover,
        [this.methodClass]: true,
        'kr-disabled':
          (this.isDisabled && !this.isApplePay) ||
          (this.isDisabled &&
            !this.isMethodAvailableInDna(this.cleanPaymentMethod)) ||
          this.disabledForm,
        'kr-loading': this.isLoading,
        'kr-apple-pay': this.isApplePay,
        'kr-apple-pay--simulator': this.isApplePay && this.isApplePaySimulator
      }
    },
    methodClass() {
      return `kr-${this.cleanPaymentMethod.toLowerCase()}`
    },
    methodLabel() {
      return this.paymentMethodToken.fields?.id?.value ?? ''
    },
    /**
     * If it's not inside the smartform, the payment mehtod must be
     * in the DNA unless it has the always visible prop
     * If it's inside, it will be visible only when there is one
     * payment method declared in the DNA and it's not an integrated
     * cards form
     */
    isVisible() {
      return (
        !this.isInsideSmartForm &&
        this.isMethodAvailableInDna(this.cleanPaymentMethod)
      )
    },
    title() {
      return this.isDisabled
        ? this.translate('smartbutton_title_disabled')
        : null
    },
    isDisabled() {
      return (
        !this.isSyncReady ||
        !this.isMethodAvailableInDna(this.cleanPaymentMethod) ||
        this.$store.state.smartForm.paymentDone ||
        this.disabledForm
      )
    },
    isApplePay() {
      return this.cleanPaymentMethod === 'APPLE_PAY'
    },
    isPayPalSandbox() {
      const method = this.method
      return method === 'PAYPAL_SB' || method === 'PAYPAL_BNPL_SB'
    },
    isInsideSmartForm() {
      return this.isInSmartForm === 'true'
    },
    isLoading() {
      return !this.errorCode && this.isPaymentActive
    },
    isPaymentActive() {
      return (
        this.activeMethod === this.cleanPaymentMethod &&
        this.activeMethodMetadata === this.metadata
      )
    },
    cleanPaymentMethod() {
      return this.method.split(':')[0]
    },
    metadata() {
      return this.method.split(':')[1] ?? null
    },
    isButtonAvailable() {
      return (
        !this.isDisabled ||
        (!this.isSyncReady &&
          this.isMethodAvailableInDna(this.cleanPaymentMethod))
      )
    },
    method() {
      return this.paymentMethodToken.paymentMethodType
    },
    reference() {
      return `${this.method}:${this.paymentMethodToken?.token}`
    }
  },
  watch: {
    isVisible: 'parentVisibility'
  },
  async created() {
    if (!this.isUnitaryTest) {
      const icons = await loadAssets()
      this.icon =
        icons.paymentMethods[this.method] || PreloadedAssets.regular.card
    }
    this.$busOn(Events.krypton.sync.ready, () => {
      this.isSyncReady = true
    })
  },
  mounted() {
    this.parentVisibility(this.isVisible)
  },
  methods: {
    ...mapActions(['openModal']),
    resolveMethod() {
      const baseMethod = this.paymentMethodToken.paymentMethodType
      return baseMethod.startsWith('PAYPAL') ? 'PAYPAL' : baseMethod
    },
    openCard() {
      if (this.isDisabled || this.isLoading) return

      const layouts = Modal.layouts
      this.openModal({
        layout: layouts[this.resolveMethod()].confirmTokenPayment,
        method: this.paymentMethodToken.paymentMethodType,
        paymentMethodToken: this.paymentMethodToken.token
      })
    },
    parentVisibility(val) {
      if (this.$el?.parentElement)
        this.$el.parentElement.style.display = val ? 'block' : 'none'
    }
  }
}
</script>
