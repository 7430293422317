var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "kr-focus-input-handler-ghost-wrapper",
    attrs: { "kr-resource": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }