import { isEmpty, omit } from 'underscore'
import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import payment from '@/common/util/payment'
import Events from '@/configuration/Events'

/**
 * This class handles the reception of the Delete token store action
 * from any of the iframes and handles validation on the ghost
 * and executing the workflow
 */
export default class ApplePayPaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'applePayPayment')
    this.browserRequests = $locator.browserRequests
    this.setupListeners()
  }

  setupListeners() {
    /*
     * For apple pay with extra fields, need to save the payment token data to be
     * used later after the extras form validation
     */
    this.$bus.$on(Events.ghost.applePay.paymentToken, ({ paymentToken }) => {
      this.paymentToken = paymentToken
    })
  }

  /**
   * Apple Payment
   *
   * @method onEvent
   * @param {Apple_Pay_Payment_Action} applePayPayment get Apple Pay payment store action
   */
  async onEvent({ paymentToken }) {
    try {
      // For apple pay with extra fields
      if (isEmpty(paymentToken) && this.paymentToken)
        paymentToken = this.paymentToken

      await super.onEvent({ paymentToken })

      if (this.$store.getters.extrasFormHasData) {
        // Required to properly reset form $ button after error
        this.$store.dispatch('paymentStart')

        // Validate extra fields form
        this.extraFieldValidation()
      }

      // get the payment token for the simulator
      if (this.$store.getters.isApplePaySimulator)
        paymentToken = this.$store.getters.getApplePayTestToken

      // Process the payment
      const { response } = await this.processApplePayPayment(paymentToken)
      // Validate the response
      if (this.isValid(response)) {
        // Transaction - send the data to the payment handler
        payment.handler(response.answer, this.$locator)
      } else {
        this.onError({ metadata: { answer: response.answer } })
      }
    } catch (error) {
      this.onError(error)
    }
  }

  onError(error, path = 'ghost/service/ApplePayPaymentTrigger.onEvent') {
    error.paymentMethod = 'APPLE_PAY'
    super.onError(error, path)

    if (!this.$store.getters.isExtrasFormVisible)
      this.$store.dispatch('closeMethod')

    this.proxy.send(this.storeFactory.create('applePayPaymentError'))
  }

  /**
   * Calls the ProcessPayment
   *
   * @param {Object} paymentToken
   * @returns {Promise}
   */
  async processApplePayPayment({
    paymentData,
    paymentMethod,
    transactionIdentifier
  }) {
    const { formToken, publicKey, remoteId } = this.$store.state
    const objectData = {
      formToken,
      publicKey,
      paymentForm: {
        walletPayload: JSON.stringify({
          token: {
            paymentData,
            paymentMethod,
            transactionIdentifier
          }
        }),
        brand: 'APPLE_PAY',
        ...this.getFormAdditionalData()
      },
      remoteId,
      clientVersion: this.restAPI.getClientVersion(),
      device: this.restAPI.getDeviceData(),
      wsUser: this.restAPI.getWSUser()
    }
    const url = this.restAPI.addJSessionID(
      this.restAPI.getProcessPaymentUrl(this.endpoint)
    )

    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }

  /**
   * Validates the server response object
   *
   * @param {*} response
   * @returns {boolean}
   */
  isValid(response) {
    return !(
      response.status === 'ERROR' ||
      response.answer?.errorCode ||
      response.answer.clientAnswer?.transactions[0].errorCode
    )
  }

  /**
   * Returns the additional data for the form
   * @returns {Object}
   */
  getFormAdditionalData() {
    const { state } = this.$store
    const nonSensitiveValues = {}
    const formId = state.forms[state.activeForm]
    state.extrasForm.fields.forEach(fieldName => {
      nonSensitiveValues[fieldName] =
        state[`cardForm_${formId}`].nonSensitiveValues[fieldName]
    })

    if (nonSensitiveValues.installmentNumber == '-1')
      delete nonSensitiveValues.installmentNumber

    return nonSensitiveValues
  }

  extraFieldValidation() {
    const { state } = this.$store
    const formId = state.forms[state.activeForm]

    // Add minimal required info for field validation
    // Don't need any more as we will never validate a sensitive data field from here
    const fields = {}

    Object.keys(state.extrasForm.dna).forEach(fieldName => {
      const strategy = this.$locator.strategyDetector.get(fieldName, formId)
      const validate = () => strategy.validate()
      fields[fieldName] = { validate, formId }
    })

    const errors = this.$locator.validationManager.validate(formId, fields)
    if (errors) throw errors
  }
}
