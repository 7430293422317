var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasValidToken || _vm.isSmartFormPopin,
          expression: "hasValidToken || isSmartFormPopin",
        },
      ],
      staticClass: "kr-smart-form-wrapper",
      class: _vm.dynamicClasses.wrapper,
    },
    [
      _vm.isSmartFormForcedToken
        ? _c("PaymentTokenSmartButton", {
            attrs: { paymentMethodToken: _vm.smartFormPaymentToken },
          })
        : _vm._e(),
      _vm.isSmartFormPopin
        ? _c("SmartFormTabHandler", {
            attrs: { position: "first", setup: _vm.isSmartFormOpen },
          })
        : _vm._e(),
      _vm.hasModalButton
        ? _c("SmartFormModalButton", {
            on: { open: _vm.openSmartFormPopinWrapper },
          })
        : _vm.hasRootMethodsList
        ? _c("SmartFormEmbeddedMethodsList", {
            scopedSlots: _vm._u(
              [
                {
                  key: "cardForm",
                  fn: function () {
                    return [
                      _vm.renderEmbeddedForm ? _vm._t("default") : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("SmartFormLoadingOverlay"),
      _c("SmartFormModal", {
        attrs: { open: _vm.isSmartFormOpen, view: _vm.currentView },
        scopedSlots: _vm._u(
          [
            {
              key: "full",
              fn: function () {
                return [
                  _vm.isSmartFormPopin
                    ? _c("SmartFormModalMethodsList", {
                        attrs: {
                          mode: _vm.isGroupingActivated ? "groups" : "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cardForm",
                              fn: function () {
                                return [
                                  !_vm.renderEmbeddedForm
                                    ? _vm._t("default")
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "group",
              fn: function () {
                return [
                  _vm.isSmartFormPopin && _vm.isGroupActive
                    ? _c("SmartFormModalBackButton")
                    : _vm._e(),
                  _vm.isGroupingActivated && _vm.isGroupActive
                    ? _c("SmartFormModalMethodsList", {
                        attrs: { mode: "list" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cardForm",
                              fn: function () {
                                return [
                                  !_vm.renderEmbeddedForm
                                    ? _vm._t("default")
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("SmartFormModalMethodsList", {
                    attrs: { mode: "method" },
                    on: { back: _vm.navigateBack },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cardForm",
                          fn: function () {
                            return [
                              !_vm.renderEmbeddedForm
                                ? _vm._t("default")
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "extras",
              fn: function () {
                return [
                  _vm.isSmartFormPopin
                    ? _c("ExtrasForm", {
                        attrs: { "display-mode": "embedded" },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [
                  _c("div", { staticClass: "kr-smart-form-methods" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "kr-methods kr-method-active kr-method-cards",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kr-method-content kr-disabled-back-button",
                          },
                          [_vm._t("extra")],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("SimpleModal"),
      !_vm.isSmartFormPopin
        ? _c("ExtrasForm", { attrs: { "display-mode": "modal" } })
        : _vm._e(),
      _vm.isSmartFormPopin ? _c("SmartFormTabHandler") : _vm._e(),
      _c("SmartFormLoadingOverlay", {
        attrs: { "only-window-redirection": true },
      }),
      _c("KryptonLayer", {
        attrs: { mode: _vm.isSmartFormPopin ? "box" : "unified" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }