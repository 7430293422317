import ApplePayManager from '@/host/service/ApplePayManager'
import { capitalize } from '../../common/util/string'
import { CoBrands } from '@/configuration/sources/Brands.yml'
import { brands as debitBrands } from '@/configuration/sources/DebitCardList.yml'
import {
  brandFormat,
  brandCorrespondence
} from '@/configuration/sources/smartform/applePay.yml'

export const getApplePayState = () => {
  return {
    applePay: {
      testMode: false,
      real: {
        ready: false,
        open: false
      },
      simulator: {
        ready: false,
        open: false,
        card: null
      }
    }
  }
}

export const applePayActions = ($locator, app) => {
  return {
    createApplePaySession({ state, dispatch, commit }) {
      const {
        applePay: { testMode }
      } = state
      if (testMode) return

      try {
        $locator.applePayManager = new ApplePayManager($locator)
        const { applePayManager } = $locator
        applePayManager.initialize()
      } catch (e) {
        if (e.name === 'InvalidAccessError') {
          dispatch('error', 'CLIENT_507')
          throw new Error('Could not start ApplePay session', { cause: e })
        }
      }
    },
    startApplePayPayment({ state, dispatch, commit }) {
      const { applePayManager } = $locator
      const {
        applePay: { testMode }
      } = state

      dispatch('cleanError')
      commit('UPDATE', { smartForm: { activeMethod: 'APPLE_PAY' } })
      if (testMode) {
        return dispatch('openApplePaySimulatorModal')
      }

      applePayManager.start()
      dispatch('applePayReady')
    },
    applePayReady({ dispatch, commit }) {
      commit('UPDATE', { applePay: { real: { ready: true } } })
    },
    applePayMode({ dispatch, commit, getters, state }) {
      const { testKeys } = state
      const { isApplePayCompatible } = getters

      if (isApplePayCompatible) return

      if (testKeys) {
        commit('UPDATE', {
          applePay: {
            testMode: true
          }
        })
        dispatch('loadApplePaySimulator')
      } else {
        dispatch('removePaymentMethod', 'APPLE_PAY')
        dispatch('removeDnaPaymentMethod', 'APPLE_PAY')
      }
    },
    openApplePaySimulatorModal({ commit }) {
      commit('UPDATE', {
        applePay: {
          simulator: {
            open: true
          }
        }
      })
    },
    closeApplePaySimulatorModal({ commit, getters }) {
      if (!getters.isApplePayOpen) return

      commit('UPDATE', {
        applePay: {
          simulator: {
            open: false
          }
        }
      })
    },
    applePayRealModalOpened({ commit }) {
      commit('UPDATE', {
        applePay: {
          real: {
            open: true
          }
        }
      })
    },
    applePayRealModalClosed({ commit }) {
      commit('UPDATE', {
        applePay: {
          real: {
            open: false
          }
        }
      })
    },
    async loadApplePaySimulator() {
      const module = await import(
        /* webpackChunkName: "kr-simulator-apple-pay" */ '@/simulator/applepay/Loader'
      )
      const loader = module.default
      loader($locator)
    },
    setApplePaySimulatorCard({ commit }, card) {
      commit('UPDATE', {
        applePay: {
          simulator: {
            card
          }
        }
      })
    },
    closeApplePay({ dispatch, getters }) {
      const { isApplePaySimulatorOpen, isExtrasFormVisible } = getters

      if (isApplePaySimulatorOpen) dispatch('closeApplePaySimulatorModal')
      if (isExtrasFormVisible) dispatch('hideExtrasForm')
    },
    resetApplePay({ commit }) {
      commit('UPDATE', {
        applePay: {
          real: {
            open: false
          },
          simulator: {
            open: false,
            card: null
          }
        }
      })
    }
  }
}
export const applePayMutations = {}
export const applePayGetters = {
  isApplePayActive: (state, { isActiveMethod }) => isActiveMethod('APPLE_PAY'),
  /**
   * Display ApplePay Button if browsers meets requirements
   * (iOS 16+ or Apple device + Safari)
   * OR if simulator is ready
   *
   * @returns {boolean}
   * @since KJS-2422
   */
  isApplePayCompatible: (
    state,
    { isIos, isMacOs, isSafari, majorOsVersion }
  ) => {
    const isIos16 = isIos && majorOsVersion >= 16
    return (
      (((isIos || isMacOs) && isSafari) || isIos16) &&
      ApplePayManager.canStartSession()
    )
  },
  /**
   * If simulator is ready, depends on simulator.open.
   * else it should be based on the real ApplePay modal.
   *
   * @returns {boolean}
   * @since KJS-2422
   */
  isApplePayOpen: ({ applePay: { simulator, real } }) =>
    (simulator.ready && simulator.open) || (real.ready && real.open),
  isApplePaySimulatorOpen: ({ applePay: { simulator } }) => simulator.open,
  isApplePayRealOpen: ({ applePay: { real } }) => real.open,
  /**
   * Returns whether or not the simulator is active and ready.
   *
   * @returns {boolean}
   */
  isApplePaySimulator: ({
    applePay: {
      testMode,
      simulator: { ready }
    }
  }) => testMode && ready,
  /**
   * Gets the test card object set on the simulator.
   *
   * @returns {object}
   */
  applePaySimulatorCard: ({
    applePay: {
      simulator: { card }
    }
  }) => card,
  getApplePayTestPayload: (state, getters) => ({
    methodName: 'https://apple.com/apple-pay',
    token: getters.getApplePayTestToken
  }),
  getApplePayTestToken: ({
    applePay: {
      simulator: {
        card: { pan, brand }
      }
    }
  }) => {
    if (!brand || !pan) throw 'CLIENT_311'

    // LATAM brand variation to generic brand
    let formattedBrand = CoBrands.brands[brand] ?? brand

    let type = debitBrands.includes(formattedBrand) ? 'debit' : 'credit'

    // Specific changes of sub-brands must adapt to applePay supported brands
    formattedBrand = brandCorrespondence[formattedBrand] ?? formattedBrand

    // Adapt brand to the format used by ApplePay or empty string if not supported
    formattedBrand = capitalize(brandFormat[formattedBrand]) ?? ''

    return {
      paymentData: {
        data: 'PAN_' + pan.replaceAll(' ', ''),
        signature: 'NA',
        header: {
          publicKeyHash: 'NA',
          ephemeralPublicKey: 'NA',
          transactionId: 'NA'
        },
        version: 'EC_v1'
      },
      paymentMethod: {
        displayName: `${formattedBrand} ${pan.slice(-4)}`,
        network: formattedBrand,
        type
      }
    }
  }
}
