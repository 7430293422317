/**
 * Used as an abstraction layer to get left and right border position
 * of a VisualViewport or DOMRect object.
 *
 * @since KJS-2523
 */
export default class ViewArea {
  /**
   * @param {DOMRect|null} area
   */
  static create(area) {
    if (area instanceof DOMRect) {
      return new ViewAreaDOMRect(area)
    } else if (area === null) {
      return ViewArea.isVisualViewportDefined()
        ? new ViewAreaViewport(window.visualViewport)
        : new ViewAreaDOMRect(document.documentElement.getBoundingClientRect())
    }
    throw new TypeError(
      `ViewArea.create: expected area to be VisualViewport or DOMRect`
    )
  }

  static isVisualViewportDefined() {
    return (
      typeof VisualViewport === 'function' &&
      window.visualViewport instanceof VisualViewport
    )
  }

  constructor(area) {
    this.area = area
  }
}

class ViewAreaViewport extends ViewArea {
  left() {
    return this.area.pageLeft
  }

  right() {
    return this.area.pageLeft + this.area.width
  }
}

class ViewAreaDOMRect extends ViewArea {
  left() {
    return this.area.left
  }

  right() {
    return this.area.left + this.area.width
  }
}
