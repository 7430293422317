<template lang="pug">
  .kr-smart-form-wallet(:class="dynamicClasses")
    .kr-smart-form-list-section-name {{ header | capitalize | colon }}
    SmartFormWalletCard(
      v-for="(card, key) in cardTokens",
      v-on:selected="onSelected"
      :key="card.token",
      :card="card"
      :locked="allLocked"
    )
    SmartFormWalletMethod(
      v-for="(method, key) in smartFormTokens",
      v-on:selected="onSelected"
      :key="method.token",
      :method="method"
      :locked="allLocked"
    )
    SmartFormWalletError
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import SmartFormWalletError from '@/host/components/smartform/wallet/Error'
import SmartFormWalletCard from '@/host/components/smartform/wallet/WalletCard'
import SmartFormWalletMethod from '@/host/components/smartform/wallet/WalletMethod'

export default {
  name: 'SmartFormWallet',
  components: {
    SmartFormWalletCard,
    SmartFormWalletMethod,
    SmartFormWalletError
  },

  props: {
    compact: {
      type: Boolean,
      default: false
    },
    allLocked: {
      type: Boolean,
      default: false
    },
    methods: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['translate', 'cardsNotIntegrated']),
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || [],
      smartFormTokens: state => state.dna?.tokens?.smartForm || []
    }),
    ...mapState(['amount']),
    header() {
      if (!this.amount) {
        return this.translate('smartform_wallet_remove_payment_method')
      }
      return this.translate('smartform_wallet_mycard')
    },
    dynamicClasses() {
      return {
        'kr-smart-form-wallet--compact': this.compact
      }
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
