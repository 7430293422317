import Zepto from 'zepto-webpack'
import { uniqueId } from 'underscore'
import { injectIntoHead } from '@/common/util/style'
import KryptonAlertPopin from '@/host/components/controls/KryptonAlertPopin'

import appLoader from '@/common/loader/app'

/**
 * Only throw the error alert if ther is some kr-embedded on the view
 * or if the smart form is not supported
 *
 * @see KJS-2626 | Add "shouldShowAlert" getter to display CLIENT_100
 *    When a smartform is given an empty formToken.
 */
export default locator => {
  const { getFormElement, getSmartFormElement } = locator.$store.getters
  return () => {
    if (getFormElement() || getSmartFormElement()) {
      injectIntoHead(locator.formStyler.getErrorPanelStyles())
      const seed = uniqueId('kr-alert-popin-')
      Zepto('body').append(
        `<div kr-resource id="${seed}" class="kr-alert-popin"><krypton-alert-popin></krypton-alert-popin></div>`
      )
      const $el = Zepto(`#${seed}.kr-alert-popin`)
      Zepto(document).ready(() => {
        appLoader(locator, $el, {
          el: `#${seed}`,
          components: { KryptonAlertPopin }
        })
      })
    }
  }
}
