<template lang="pug">
    .krypton-tab-handler
      input.kr-smart-form-focus-handler(type="text", @focus="onFocus", style="position: fixed;left: -9999px;top: -9999px;")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SmartFormTabHandler',
  props: {
    position: {
      type: String,
      default: 'last'
    },
    setup: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod
    }),
    ...mapGetters(['isFormPopin'])
  },
  watch: {
    setup: 'setupGlobalListener'
  },
  methods: {
    ...mapActions(['focusNext', 'focusPrevious']),
    setupGlobalListener() {
      if (this.position === 'first' && this.setup) {
        const tabListener = e => {
          if (e.key === 'Tab') {
            this.onFocus(e)
            e.preventDefault()
            document.removeEventListener('keydown', tabListener)
          }
        }
        document.addEventListener('keydown', tabListener)
      }
    },
    onFocus(e) {
      if (this.setup) this[e.shiftKey ? 'focusPrevious' : 'focusNext']()
    }
  }
}
</script>
