import Events from '@/configuration/Events'

export const getToolbarState = () => {
  return {
    toolbar: {
      reload: false
    }
  }
}

export const toolbarActions = $locator => {
  return {
    async loadToolbar({ state, commit }) {
      // If it's chrome40 or minor, doesn't show the toolbar
      if (
        state.browser?.name === 'Chrome' &&
        parseFloat(state.browser?.version) <= 40
      )
        return
      // The toolbar is only creaed on the host
      const isHost = state.application === 'host'
      // Only create the toolbar if has not already been created and the public key is test
      if (isHost && state.dna && state.testKeys && KR.toolbarReady === false) {
        KR.toolbarReady = true

        //invoketoolbar
        if (state.hiddenToolbar) return false

        const lazyLoader = $locator.lazyLoader
        const $bus = $locator.$bus
        const timerMan = $locator.timerManager

        $bus.$on(Events.krypton.destroy, message => {
          // Clean all events
          KR.toolbarReady = false
          commit('UPDATE', { toolbar: { reload: true } })
        })

        timerMan.register('start', 'creatingToolbar', 'Creating toolbar', true)

        if (!state.toolbar.reload) {
          const toolbarCSS = `${state.baseAddress}/checkout/css/toolbar.min.css?assets=%%build_number%%`
          await lazyLoader.load(toolbarCSS, 'css')
          const animateCSS = `${state.baseAddress}/vendors_static/css/animate.min.css?assets=%%build_number%%`
          await lazyLoader.load(animateCSS, 'css')
        }

        const module = await import(
          /* webpackChunkName: "kr-toolbar" */ '@/toolbar/Loader'
        )
        const loadToolbar = module.default
        loadToolbar($locator)
        timerMan.register('end', 'creatingToolbar')
      }
    }
  }
}

export const toolbarMutations = {}
export const toolbarGetters = {}
