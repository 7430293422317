import _ from 'underscore'
import Zepto from 'zepto-webpack'
import { isLoadedLocally } from '@/common/util/url'

export default class Communicator {
  constructor(application, locator) {
    this.source = application
    this.$locator = locator
    this.listeners = []

    this.init()
  }

  /**
   * Bootstrap method of the class. Will create the method
   * that will report all listeners the messages received from
   * other iframes
   */
  init() {
    window.addEventListener(
      'message',
      e => {
        this.listeners.forEach(listener => {
          listener(e.data)
        })
      },
      false
    )
  }

  /**
   * Adds a listener to the received messages from the iframes
   *
   * @param {Function} listener Listener method
   */
  addListener(listener) {
    // Only 1 listener allowed
    if (_.isFunction(listener)) this.listeners = [listener]
  }

  /**
   * Sends a message through the IFrame (parent or children)
   *
   * @param {string} msg Message to send
   */
  send(msg, toChildWin = false) {
    if (this.source === 'host') {
      if (!isLoadedLocally(window.location.href)) {
        if (toChildWin) this.sendToWindow(msg)
        else this.sendToIframe(msg)
      }
    } else {
      let target = window.parent // Iframe
      if (window.opener) target = window.opener // Window
      if (target && window.FIELD_CONFIGURATION) {
        if (!isLoadedLocally(window.FIELD_CONFIGURATION.parentDomain)) {
          target.postMessage(msg, window.FIELD_CONFIGURATION.parentDomain)
        }
      }
    }
  }

  /**
   * Sends the message to the ghost
   *
   * @param {*} msg
   */
  sendToIframe(msg) {
    const iframeList = ['#krcheckout']

    for (const sel of iframeList) {
      const $iframe = Zepto(sel)
      if ($iframe && $iframe.attr('src')) {
        const targetSrc = $iframe.attr('src').split('?')[0]
        $iframe[0].contentWindow.postMessage(msg, targetSrc)
      }
    }
  }

  /**
   * Sends the message to the window (redirect)
   *
   * @param {*} msg
   */
  sendToWindow(msg) {
    const targetWin = window.redirectionWindow
    const { baseAddress } = this.$locator.$store.state

    targetWin.postMessage(msg, `${baseAddress}/redirect/redirect.html`)
  }
}
