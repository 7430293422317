import { isEmpty, isString, last } from 'underscore'
import { mapActions, mapState, mapGetters } from 'vuex'
import Events from '@/configuration/Events'
import ErrorConf from '@/configuration/sources/ErrorsConfiguration.yml'

import { capitalize } from '@/common/util/string'
import { wait } from '@/common/util/async'

export const PaymentMixin = {
  computed: {
    ...mapState([
      'postUrlSuccess',
      'postUrlRefused',
      'getUrlSuccess',
      'getUrlRefused',
      'formToken',
      'formTokenConsumed',
      'transactions'
    ]),
    ...mapState({
      errorCode: state => state.error.errorCode,
      orderCycle: state => state.error.orderCycle,
      delayedPaymentDoneRed: state => state._internals.delayedPaymentDoneRed
    }),
    ...mapGetters(['getFormElement', 'isSmartForm']),
    orderCycleWrapper() {
      return this.errorCode && this.orderCycle
    }
  },
  watch: {
    orderCycleWrapper(cycle) {
      if (!this.isSmartForm || cycle !== 'CLOSED') return
      this.disableForm()
      const { storeFactory } = this.$locator
      const store = storeFactory.create('payment', last(this.transactions))
      this.paymentDone(store, 'refused')
    },
    errorCode(code) {
      if (~ErrorConf.disableCardForm.indexOf(code)) this.disableCardForm()
      // call refused
      if (code !== 'PSP_099') return
      const { storeFactory } = this.$locator
      const store = storeFactory.create('payment', last(this.transactions))
      this.paymentDone(store, 'refused')
    }
  },
  created() {
    this.paymentSubscriptions()
  },
  methods: {
    ...mapActions(['disableCardForm', 'paymentEnd', 'disableForm']),
    paymentSubscriptions() {
      this.$busOn(Events.krypton.form.pay, this.sendPayAction)
      this.$busOn(Events.krypton.message.payment, this.paymentDone)
    },

    /**
     * Executes the pay action try, sending to checkout
     * iframe the action to collect and validate data.
     */
    sendPayAction(formId) {
      if (this.context.formId !== formId) return
      if (!this.formTokenConsumed) {
        this.$store.dispatch('paymentStart')
        const extra = this.readExtraFields()
        // Pay action
        const pay = this.$locator.storeFactory.create('pay', {
          formId,
          formToken: this.formToken,
          extra
        })
        this.$locator.proxy.send(pay)
      }
    },

    /**
     * This values are fetched from all the fields not starting the
     * name by "kr", and are returned as an object (key => value) as
     * (name => definition)
     *
     * Also attaches the HTML5 validation object to the field
     */
    readExtraFields() {
      const $form = this.getFormElement()
      const result = {}

      const $inputs = $form.querySelectorAll(
        'input:not([kr-field]),select:not([kr-field])'
      )
      // for..of of domElements has no iterablity on ie11
      for (let i = 0; i < $inputs.length; i++) {
        const input = $inputs[i]
        const inputName = input.getAttribute('name')

        if (isString(inputName)) {
          this.$store.dispatch('showField', inputName)
          const value = this.getExtraInputValue(input, result[inputName])
          result[inputName] = {
            name: inputName,
            value,
            required:
              result[inputName]?.required || input.hasAttribute('required')
          }
        }
      }

      return result
    },

    getExtraInputValue(input, previousInput) {
      switch (input.getAttribute('type')) {
        case 'checkbox':
          return input.checked
        case 'radio':
          if (previousInput?.value) return previousInput.value
          return input.checked ? `${input.value}` : null
        default:
          return `${input.value}`
      }
    },

    /**
     * Performs the payment done action. It should receive a store to perform
     * this action and the next options are available:
     */
    async paymentDone(store, opType = 'success') {
      // Flag to allow a delayed redirection - necessary to test the behavior during the redirection (see KJS-3731)
      if (!!this.delayedPaymentDoneRed) await wait(2000)
      // Stop execution for components not in the event source form
      // We only get the formId from card payments (don't stop execution for other payment means)
      if (store.formId && store.formId !== this.context.formId) return

      // Search again to redefine the wrapper
      const type = capitalize(opType)
      // Detect the url to go and the mode
      const url = this[`postUrl${type}`] || this[`getUrl${type}`]
      const mode = this[`postUrl${type}`] ? 'POST' : 'GET'

      try {
        if (type === 'Success') this.disableCardForm()
        // KR.onSubmit / onTransactionCreated interception check
        await this.intercept({
          name: 'onSubmit',
          args: [store, this.context.formId]
        })
        await this.intercept({
          name: 'onTransactionCreated',
          args: [store, this.context.formId]
        })
        // Payment interception check
        const interceptedFlag = await this.shouldBeIntercepted(store, opType)
        const jsonToPost = store._name === 'payment' ? store.post() : store
        const form = await this.factoryForm(url, jsonToPost, mode)
        if (!interceptedFlag) this.postData(form)
      } catch (error) {
        this.paymentEnd()
      }
    },

    /**
     * Returns a Promise with a result boolean indicating if the form should
     * be intercepted or not.
     */
    async shouldBeIntercepted(store, opType = 'success') {
      const api = this.$locator.api
      // If the form id is not set, refresh it now
      if (!store.formId) store.formId = this.context.formId

      const formsEndpoint = `/intercepted${capitalize(opType)}Forms`

      const result = await api.get(formsEndpoint)

      const interceptedForms = result.response.interceptedForms
      if (isEmpty(interceptedForms)) return false

      // checks first if the post has been intercepted
      const hasListenerOnForm = this.context.formId in interceptedForms
      const hasGlobalListener = '*' in interceptedForms

      let callback
      if (hasGlobalListener) callback = interceptedForms['*']
      if (hasListenerOnForm) callback = interceptedForms[this.context.formId]
      // if postAction is false, stops the post
      if (callback) {
        let callbackResult = callback(store)
        if (!callbackResult) return true
      }

      return false
    }
  }
}
