import { mapActions, mapGetters, mapState } from 'vuex'

import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export default {
  computed: {
    ...mapGetters(['translate', 'sortedPaymentMethods']),
    ...mapState(['dna']),
    ...mapState({
      allowedPaymentMethods: state => state.smartForm.allowedPaymentMethods,
      paymentMethods: state => state.smartForm.availablePaymentMethods,
      userPaymentMethodsOrder: state => state.smartForm.userPaymentMethodsOrder
    })
  },
  watch: {
    dna: 'updateAvailablePaymentMethods',
    allowedPaymentMethods: 'updateAvailablePaymentMethods',
    userPaymentMethodsOrder: 'updateMethodGroups'
  },
  created() {
    this.updateAvailablePaymentMethods()
  },
  methods: {
    ...mapActions(['setAvailablePaymentMethods', 'setPaymentMethodGroups']),
    updateMethodGroups() {
      this.setPaymentMethodGroups(this.sortedPaymentMethods)
    },
    updateAvailablePaymentMethods() {
      let paymentMethods = []
      if (this.allowedPaymentMethods.includes('all')) {
        paymentMethods = this.paymentMethods
      } else {
        paymentMethods = this.paymentMethods.filter(method =>
          this.allowedPaymentMethods.includes(method.toUpperCase())
        )
      }
      this.setAvailablePaymentMethods(paymentMethods)
    }
  }
}
