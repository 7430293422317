<template lang="pug">
  SmartButtonSkeleton(v-if ="(!isSyncReady && isVisible) || !hasValidToken")
  .kr-smart-button.kr-sm-internal(
    v-else-if="isSyncReady && isVisible"
    :class="dynamicClasses", 
    @click="triggerMethod(paymentMethod, $event)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :title="title"
    :kr-payment-method="cleanPaymentMethod.toLowerCase()"
    :kr-brand="brands.toLowerCase()"
    :style="styles.button"
      )

    SmartFormRadioButton(v-if="showRadioButton", ref="radioButton", :checked="isSelected", :hover="hover")

    // Regular label
    .kr-method-info(v-if="((customLabel && brands) || cleanPaymentMethod !== 'CARDS') && (!spinnerVisible || !isLoading)")
      .kr-method-icon(v-if="icon", v-html="getCustomPaymentMethodIcon(method) || icon")
      .kr-label
        label.kr-method-label {{getCustomPaymentMethodLabel(method) || methodLabel}}
        label.kr-amount-label(v-if="!hasCustomLabelWithAmount") {{amountLabel}}
      span.kr-method-badge(v-if="applePaySimulator") Simulator
      span.kr-method-badge(v-else-if="isPayPalSandbox") Sandbox
    // Icons label for card
    .kr-method-info(v-else-if="(!spinnerVisible || !isLoading)")
      SmartFormCardIcons(
        :brands="displayedBrands",
        :brand-filter="brandFilter",
        :margin="5",
        :is-smart-button="true",
        @num-of-brands="setNumOfBrands"
      )
      .kr-label(v-if="hasSingleBrand")
        label.kr-method-label.kr-single-brand-label {{methodLabel}}
        label.kr-amount-label {{amountLabel}}
      label.kr-amount-label(v-else) {{amountLabel}}
    .kr-method-info(v-if="spinnerVisible && isLoading", :class="{'kr-hidden': spinnerVisible && isLoading}")
    KryptonLoadingAnimation(v-if="spinnerVisible && isLoading")
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { titleize } from '@/common/util/string'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { loadAssets } from '@/common/loader/assets'

import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import SmartFormCardIcons from '@/host/components/smartform/CardIcons'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import SmartButtonSkeleton from '@/host/components/smartbutton/Skeleton'
import { ApplePayMixin } from '@/host/components/mixins/ApplePay'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export default {
  name: 'SmartButton',
  components: {
    KryptonLoadingAnimation,
    SmartFormCardIcons,
    SmartFormRadioButton,
    SmartButtonSkeleton
  },
  mixins: [ApplePayMixin, SmartFormClickMixin],
  props: {
    method: { type: String, default: '' },
    brands: { type: String, default: '' },
    customLabel: { type: String, default: '' },
    brandFilter: { type: String, default: '' },
    isInSmartForm: { type: String, default: 'false' }
  },
  data() {
    return {
      hover: false,
      icon: null,
      isSyncReady: false,
      paymentMethod: '',
      hasNoCards: false
    }
  },
  computed: {
    ...mapState([
      'amountLabel',
      'isUnitaryTest',
      'language',
      'disabledForm',
      'disabledCardForm'
    ]),
    ...mapGetters([
      'translate',
      'isMethodAvailable',
      'isMethodAvailableInDna',
      'isSinglePaymentButton',
      'hasSeveralPaymentMethods',
      'hasPaymentMethods',
      'hasSmartButton',
      'numOfPaymentMethods',
      'cardsNotIntegrated',
      'hasCardMethodAvailable',
      'getCustomPaymentMethodLabel',
      'getCustomPaymentMethodIcon',
      'isApplePaySimulator',
      'isFallbackSmartButtonVisible',
      'isSelectedMethod',
      'hasValidToken'
    ]),
    ...mapState({
      cardBrands: state => state.smartForm.cardBrands,
      errorCode: state => state.error.errorCode,
      activeMethod: state => state.smartForm.activeMethod,
      activeMethodMetadata: state => state.smartForm.activeMethodMetadata,
      buttonConfig: state => state.form.smartButton.button,
      spinnerVisible: state => state.form.smartButton.button.spinnerVisible,
      paymentMethods: state => state.smartForm.availablePaymentMethods
    }),
    styles() {
      const styles = {}
      styles.button = {}
      if (this.hover) {
        styles.button.border = this.buttonConfig.$hover.border
      }
      return styles
    },
    dynamicClasses() {
      return {
        'kr-smart-button--spbtn': this.isSinglePaymentButton,
        'kr-hover': this.hover,
        [this.methodClass]: true,
        'kr-disabled':
          (this.isDisabled && !this.isApplePay) ||
          (this.isDisabled &&
            !this.isMethodAvailableInDna(this.cleanPaymentMethod)) ||
          this.disabledForm,
        'kr-loading': this.isLoading,
        'kr-apple-pay': this.isApplePay,
        'kr-apple-pay--simulator': this.isApplePay && this.isApplePaySimulator
      }
    },
    methodClass() {
      return `kr-${this.cleanPaymentMethod.toLowerCase()}`
    },
    hasCustomLabelWithAmount() {
      return this.customLabel && !!~this.customLabel.indexOf('%amount%')
    },
    methodLabel() {
      if (this.customLabel)
        return this.customLabel.replace('%amount%', this.amountLabel)
      // Specific translation for smartbutton
      const key = `smartbutton_method_${this.method.toLowerCase()}`
      if (this.translate(key) !== key) return this.translate(key)
      // Use the Pay with + method format
      return `${this.translate('smartbutton_label')} ${
        this.singleBrandLabel ||
        paymentMethodsConf.labels[this.cleanPaymentMethod] ||
        this.cleanPaymentMethod
      }`
    },
    title() {
      return this.isDisabled
        ? this.translate('smartbutton_title_disabled')
        : null
    },
    isDisabled() {
      return (
        !this.isSyncReady ||
        !this.isMethodAvailableInDna(this.cleanPaymentMethod) ||
        this.$store.state.smartForm.paymentDone ||
        this.disabledForm ||
        (this.cleanPaymentMethod === 'CARDS' && this.disabledCardForm) ||
        (this.paymentMethod === 'CARDS' && this.cardBrands.length === 0) ||
        this.hasNoCards
      )
    },
    isApplePay() {
      return this.cleanPaymentMethod === 'APPLE_PAY'
    },
    isPayPalSandbox() {
      const method = this.method || this.paymentMethod
      return method === 'PAYPAL_SB' || method === 'PAYPAL_BNPL_SB'
    },
    isInsideSmartForm() {
      return this.isInSmartForm === 'true'
    },
    /**
     * If it's not inside the smartform, the payment mehtod must be
     * in the DNA unless it has the always visible prop
     * If it's inside, it will be visible only when there is one
     * payment method declared in the DNA and it's not an integrated
     * cards form
     */
    isVisible() {
      return (
        (!this.isInsideSmartForm &&
          this.isMethodAvailableInDna(this.cleanPaymentMethod)) ||
        (this.isInsideSmartForm && this.isFallbackSmartButtonVisible)
      )
    },
    isLoading() {
      return !this.errorCode && this.isPaymentActive
    },
    hasSingleBrand() {
      return this.brands.split(',').filter(value => !!value).length === 1
    },
    singleBrandLabel() {
      return this.hasSingleBrand
        ? titleize(
            this.brands
              .split(',')[0]
              .toLowerCase()
              .split('_')
              .join(' ')
              .split('-')
              .join(' ')
              .trim()
          )
        : ''
    },
    showRadioButton() {
      return this.isSinglePaymentButton
    },
    isSelected() {
      return this.isInsideSmartForm || this.isSelectedMethod(this.method)
    },
    isPaymentActive() {
      return (
        this.activeMethod === this.cleanPaymentMethod &&
        this.activeMethodMetadata === this.metadata
      )
    },
    cleanPaymentMethod() {
      return this.paymentMethod.split(':')[0]
    },
    metadata() {
      return this.paymentMethod.split(':')[1] ?? null
    },
    brandIcons() {
      if (this.cleanPaymentMethod === 'CARDS' && !this.metadata) {
        return ''
      }
      return this.brands
    },
    isButtonAvailable() {
      return (
        !this.isDisabled ||
        (!this.isSyncReady &&
          this.isMethodAvailableInDna(this.cleanPaymentMethod))
      )
    },
    displayedBrands() {
      return this.brands !== '' ? this.brands.split(',') : this.cardBrands
    }
  },
  watch: {
    paymentMethods(val) {
      if (!this.method) this.paymentMethod = this.paymentMethods[0]
    },
    isVisible: 'parentVisibility'
  },
  async created() {
    this.paymentMethod = this.method || this.paymentMethods[0]
    if (!this.isUnitaryTest) {
      const icons = await loadAssets()
      this.icon =
        icons.paymentMethods[this.paymentMethod] || PreloadedAssets.regular.card
    }
    this.$busOn(Events.krypton.sync.ready, () => {
      this.isSyncReady = true
    })
  },
  mounted() {
    this.parentVisibility(this.isVisible)
  },
  methods: {
    ...mapActions(['openMethod', 'selectMethod']),
    async triggerMethod(method, $event) {
      // Single payment button with fallback button will always be selected by default
      // The button is only visual, the payment logic is called from the payment button
      if (this.isSinglePaymentButton && this.isFallbackSmartButtonVisible)
        return

      if (this.isSinglePaymentButton) {
        this.selectMethod(method)
        return
      }

      const action = this.method.startsWith('CARDS')
        ? 'openPopin'
        : 'beforePaymentStart'
      if (await this.interruptsExecution(this.method, action)) return false

      // If we click on the + button, the button is disabled or the payment done, we
      // avoid the method selection
      const $target = Zepto($event.target)
      if (this.isDisabled || this.isPlusLabel($target)) return

      if (!this.isApplePay) this.loading = true

      this.openMethod(method)
    },
    isPlusLabel($target) {
      return $target.hasClass('kr-extra-brands')
    },
    parentVisibility(val) {
      if (this.$el?.parentElement)
        this.$el.parentElement.style.display = val ? 'block' : 'none'
    },
    setNumOfBrands(num) {
      this.hasNoCards = num === 0
    }
  }
}
</script>
