import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'

import { fieldNameAsTranslationKey } from '@/common/util/fieldName'

export const PlaceholderMixin = {
  data() {
    return {
      forcedPlaceholder: null
    }
  },
  computed: {
    ...mapState({
      placeholders: state => state.placeholders,
      formPlaceholders: state => state.form.placeholders
    }),
    ...mapState(['os']),
    ...mapGetters(['translate']),
    placeholder() {
      // If there is a forced value, has preference
      if (!_.isNull(this.forcedPlaceholder)) return this.forcedPlaceholder
      return (
        this.formPlaceholders[this.fieldName] ||
        this.placeholders[this.fieldName] ||
        this.translate(
          `placeholder_${fieldNameAsTranslationKey(this.fieldName)}`
        )
      )
    }
  },
  methods: {
    cleanPlaceholder() {
      // If iOS10 or lower, reset the placeholder
      const os = this.os
      if (os.name === 'iOS' && parseInt(os.version.split('.')[0]) <= 10) {
        const _this = this
        this.forcedPlaceholder = ''
        _.defer(() => {
          _this.forcedPlaceholder = null
        })
      }
    }
  }
}
