import { mapGetters, mapState, mapActions } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { isFunction, isBoolean } from 'underscore'
import { PaymentButtonMixin } from '@/host/components/mixins/PaymentButton'
import { PaymentMixin } from '@/host/components/mixins/Payment'
import { PaymentPostMixin } from '@/host/components/mixins/PaymentPost'
import { FocusHelperMixin } from '@/host/components/mixins/FocusHelper'
import Events from '@/configuration/Events'

export const FormPaymentButtonMixin = {
  mixins: [
    PaymentButtonMixin,
    PaymentMixin,
    PaymentPostMixin,
    FocusHelperMixin
  ],
  inject: ['context'],
  computed: {
    ...mapState(['triggerEvent', 'processingPayment']),
    ...mapGetters([
      'isCardFormVisible',
      'isCloneForm',
      'isSinglePaymentButton',
      'hasSmartButton',
      'cardsNotIntegrated',
      'translate',
      'discount',
      'getAmountLabel'
    ]),
    ...dynamicMapState('context.namespace', ['disabledCardForm']),
    ...mapState({
      hasError: state => !!state.error.errorCode,
      events: ({ button }) => button.events,
      spinnerVisible: ({ button }) => button.spinnerVisible,
      isDisabled({ button, synced }) {
        return button.disabled || this.disabledCardForm || !synced
      }
    }),
    isVisible() {
      return (
        this.isCloneForm(this.context.formId) ||
        (this.isCardFormVisible(this.context.formId) &&
          this.config.visibility &&
          (!this.isSinglePaymentButton ||
            !this.cardsNotIntegrated ||
            this.hasSmartButton('CARDS')))
      )
    },
    dynamicClasses() {
      return {
        'kr-discount-update': this.discountUpdate,
        'kr-loading': this.isLoading
      }
    }
  },
  watch: {
    formToken: 'enableButton',
    spinnerVisible(val) {
      if (!isBoolean(val)) return
      const event = val ? 'showSpinner' : 'hideSpinner'
      if (isFunction(this.events[event])) this.events[event](this.$el)
    },
    processingPayment(val) {
      if (val && this.triggerEvent && isFunction(window[this.triggerEvent])) {
        window[this.triggerEvent]()
      } else if (!val) {
        this.enableButton()
      }
    },
    redirecting(val) {
      if (!val) this.enableButton()
    }
  },
  created() {
    this.startSubscriptions()
  },
  methods: {
    ...mapActions(['disableButton', 'enableButton', 'cleanError', 'intercept']),
    startSubscriptions() {
      // Events listening
      // Config events
      this.$busOn(Events.krypton.form.button.replace, message => {
        this.update({ button: { template: message } })
      })

      // Focus by event
      this.$busOn(Events.krypton.field.focus, msg => {
        if (msg.name === 'paymentButton' && msg.formId === this.context.formId)
          this.$el.focus()
      })
    }
  }
}
