/**
 * Add a new custom directive v-onresize to call a given callback when the
 * target DOM element is resized.
 * It uses ResizeObserver API:
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver
 *
 * Since it is not compatible with IE11 it shall be used only inside the
 * smartform until IE11 support is discontinued.
 *
 * It's also not supported by Safari 13 and below.
 *
 * @since KJS-2355
 */

const observersPool = new Map()

export default {
  directives: {
    onresize: {
      bind(el, binding, vNode) {
        if (!global.ResizeObserver) return
        const observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect
            binding.value({ width, height })
          }
        })
        observer.observe(el)
        observersPool.set(el, observer)
      },
      unbind(el, binding) {
        if (!global.ResizeObserver) return
        observersPool.get(el).unobserve(el)
        observersPool.delete(el)
      }
    }
  }
}
