<template lang="pug">  
  .kr-brand-icon(:class="[`kr-${method.toLowerCase()}`, isCards ? 'kr-cards' : '', assetsLoading ? 'kr-loading-assets' : '']", v-html="iconHtml")
</template>

<script>
import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'

export default {
  name: 'BrandIcon',
  props: {
    method: {
      type: String,
      default: 'DEFAULT'
    }
  },
  data() {
    return {
      iconHtml: PreloadedAssets.loading.pan,
      isCards: false,
      assetsLoading: true
    }
  },
  watch: {
    async method() {
      this.assetsLoading = true
      await this.loadIcon()
    }
  },
  async created() {
    await this.loadIcon()
  },
  methods: {
    async loadIcon() {
      const { regular, paymentMethods } = await loadAssets()
      this.isCards = !!regular.branded[this.method]
      this.iconHtml =
        regular.branded[this.method] ||
        paymentMethods[this.method] ||
        PreloadedAssets.regular.card

      this.assetsLoading = false
    }
  }
}
</script>
